import { useEffect, useRef, useState } from 'react';

import { ConnectedProps, connect, useSelector } from 'react-redux';
import {  
    newGameVSPlayer, 
    rivalOfferedDraw, 
    setGameStarted,
    endGame,
    cancelGame,
    drawDeclined,
    rivalDisconnected
} from '../store/gameStateSlice';
import {makeMove} from '../store/gameSlice'
import { IRootState } from '../models/models';
import { copyObj, isDev } from '../local-engine/gameplay-helper-fn';
import { 
    sendWsMessage, setBackMessage, setConnectionState, showAds 
} from '../store/topStateSlice';
import { setToken } from '../store/userSlice';
import { wsService } from '../common/ws-service';
import clockService from '../common/clock-service';


const mapState = (state: IRootState) => ({
    payload: state.topState.wsMessage,
    playerOnline: state.gameState.playerOnline,
    playerColor: state.gameState.playerColor,
    token: state.user.token,
    gameKey: state.gameState.gameKey,
    userId: state.user.userId,
})

const mapDispatch = {
    makeMove,
    newGameVSPlayer,
    sendMessage: sendWsMessage,
    rivalOfferedDraw, 
    setGameStarted,
    endGame,
    cancelGame,
    setConnectionState,
    drawDeclined,
    setBackMessage,
    setToken,
    showAds,
    rivalDisconnected
}

let timeout = null as any
let messQueue = [] as any[]

const connector = connect(mapState, mapDispatch)
type Props = ConnectedProps<typeof connector>

const WebSocketClient = (props: Props) => {
    const tokenSend = useRef(false)
    const [ws, setWs] = useState(wsService.ws as WebSocket)
    const wsState = useSelector((state: IRootState) => state.topState.wsConnectionState)
    // isDev() && console.log('id changed', props.userId)
    useEffect(() => {
        isDev() && console.log('id changed', props.userId)
        const messageHandler = (data: any) => {
            const {message, payload, to} = data
            isDev() && console.log('new message to player', copyObj(data), props.userId)
            if (to && to !== props.userId) {
                if (!wsService.onlineBot && message === 'id'){
                    wsService.setOnlineBot(payload)
                }
                return  
            }
            if (payload === "error") {
                props.setBackMessage({msg: message, ok: false})
                return
            }
            switch (message) {
                case 'game move': {
                    props.makeMove(payload)
                    break
                }
                case 'new game': {
                    props.newGameVSPlayer(payload)
                    break
                }
                case 'game draw offered': {
                    props.rivalOfferedDraw()
                    break
                }
                case 'game rival reconnected': {
                    props.rivalDisconnected(0)
                    break
                }
                case 'game rival disconnected': {
                    props.rivalDisconnected(Date.now())
                    break
                }
                case 'game draw declined': {
                    props.drawDeclined()
                    break
                }
                case 'game canceled': {
                    props.cancelGame()
                    clockService.stopGame()
                    props.showAds(true)
                    break
                }
                case 'game over': {
                    if (!payload) {
                        console.error('invalid payload to end game', payload)
                    }
                    props.endGame(payload)
                    break
                }
                case 'game started': {
                    props.setGameStarted(true)
                    props.setToken(payload)
                    break
                }
                // case 'opponent message': {
                //     break
                // }
                // case 'common chat message': {
                //     break
                // }
                default: {
                    break
                }
            }
        }
        const unsubWs = wsService.$ws.subscribe(setWs)
        const unSubMess = wsService.$message.subscribe(messageHandler)
        return () => {
            clearTimeout(timeout)
            unsubWs()
            unSubMess()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userId])

    useEffect(() => {
        wsService.setToken(!!props.token)
    }, [props.token])

    useEffect(() => {
        isDev() && console.log('ws', ws?.readyState, tokenSend.current, props.token)
        if ((!ws || ws?.readyState !== 1 || !ws.send)) {
            const state = ws?.readyState !== 2 ? 3 - (ws?.readyState || 0) : 2
            wsState === 1 && props.setConnectionState(state)
            tokenSend.current = false
            return
        }
        if (!tokenSend.current && props.token) {
            tokenSend.current = true
            // console.log('autorize')
            const mess = JSON.stringify({
                message: 'user authorized',
                payload: {token: props.token, gameKey: props.gameKey}
            })
            ws.send(mess)
        }
        if (messQueue.length) {
            for (const mess of messQueue) {
                if (mess.message !== 'user authorized') {
                    ws.send(JSON.stringify(mess))
                }
            }
            messQueue = []
        }
        wsState !== 1 && props.setConnectionState(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws])
  
    useEffect(() => {
        isDev() && console.log('new mess', ws?.readyState, props.payload)
        if (!props.payload) return
        if (props.payload?.message === 'logout') {
            messQueue.length = 0
            clearTimeout(timeout)
            if (ws && ws.readyState === 1) {
                ws.close()
            }
            props.sendMessage(null)
            return
        }
        if (!ws || ws?.readyState !== 1) {
            messQueue.push(props.payload)
            props.sendMessage(null)
            // console.log(' mess queue', messQueue, )
            return
        }
        // props.payload 
        const payload = JSON.stringify(props.payload)
        ws.send(payload)
        isDev() && console.log('mess send', payload)
        if (props.payload) {
            props.sendMessage(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.payload])
    
   
    return <></>
};

export default connector(WebSocketClient);
