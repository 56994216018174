import {
    IPositionData,
    PieceColor as PC,
    ITower,
    MMRResult, 
    IPieces,
} from '../models/models'

import MoveResolver from './move-resolver'
import { normaFn, oppositeColor } from './gameplay-helper-fn'
import { TopLegendValues, DefaultData, EvValLim } from '../constants/gameConstants'

// const moveR = new MoveResolver({})
class Evaluator {
    moveR: MoveResolver
    positionData = {
        white: {...DefaultData},
        black: {...DefaultData},
    } as IPositionData

    constructor(moveRes: MoveResolver) {
        this.moveR = moveRes
    }

    resetData() {
        this.positionData = {
            white: {...DefaultData},
            black: {...DefaultData},
        } as  IPositionData
    }
    
    getPiecePositionValue = (piece: ITower, key: string) => {
        const {color} = piece
        const lineValue =
            color === PC.white
                ? parseInt(key.slice(1)) / this.moveR.size
                : (this.moveR.size + 1 - parseInt(key.slice(1))) / this.moveR.size
        return (lineValue) / this.moveR.size + 1
    }

    getTowerPositionValue = (tower: ITower, key: string) => {
        const {color} = tower
        const tL = TopLegendValues.slice(0, this.moveR.size)
        const index = tL.indexOf(key[0])
        const sign = index < 3 && index > this.moveR.size - 3 ? -1 : 1
        const tP = tower[color]
        const bP = tower[oppositeColor(color)]
        return 1 + sign* (normaFn(tP - bP) / (bP + tP)) / 4
    }

    EvalKingEnding = (towers: IPieces, turn: PC) => {
     
    }

    getPositionData = (towers: IPieces, turn: PC, extra = false) => {
        this.resetData()
        let mandatory = [] as MMRResult[], 
            free = [] as MMRResult[], 
            rMandatory = [] as MMRResult[], 
            rFree = [] as MMRResult[]
        const resolveMoves = (moves: MMRResult[], m: MMRResult[], f: MMRResult[]) => {
            if (!moves.length) return [m, f]
            if (moves[0].takenPieces) {
                return [m.concat(moves), f]
            } else {
                return [m, f.concat(moves)]
            }
        }
        for (let key in towers) {
            this.savePieceData(towers[key], key)
            if (towers[key].color !== turn) {
                const _moves = this.moveR.getPieceMoves(towers,  key)
                const [m, f] = resolveMoves(_moves, rMandatory, rFree)
                rMandatory = m; rFree = f
                continue
            }
            const _moves = this.moveR.getPieceMoves(towers,  key)
            const [m, f] = resolveMoves(_moves, mandatory, free)
            mandatory = m; free = f
        }
        this.positionData[turn].moveNumber = mandatory.length + free.length
        this.positionData[oppositeColor(turn)].moveNumber = rMandatory.length + rFree.length
        const moves = this.moveR.getPossibleMovesForTree(mandatory.length ? mandatory : free)
        const value = this.getPositionValue()
        if (value > EvValLim) {throw new Error('invalid val')}
        return !extra 
        ? {
            moves,
            deepValue: { depth: 0, value, move: '' }
        }
        : {
            deepValue: { depth: 0, value, move: '' },
            na: this.advantageInNumberOfMoves(),
            pa: this.advantageInPieces(),
            ta: this.advantageInTowers(),
            pd: this.positionData,
            mad: this.materailAdvantage()
        }
    }

    getPositionValue = () =>  {
        const advInMoves = this.advantageInNumberOfMoves()
        const matAdv = this.materailAdvantage()
        // const advInPices = this.advantageInPieces()
        // const advInTowers = this.advantageInTowers()
        // const val = advInPices + advInMoves + advInTowers
        return Math.floor(normaFn(advInMoves + matAdv) * 300) * EvValLim / 1000
    }

    avaluatePiece = (tower: ITower, key: string) => {
        const piecePositionValue = this.getPiecePositionValue(tower, key)
        const { king, color } = tower
        this.positionData[color].pieces += king 
            ? 2.2 
            : piecePositionValue
        this.positionData[color].kings += king ? 1 : 0
    }

    evaluateTower = (tower: ITower, key: string) => {
        const {color, king} = tower 
        const pieceValue = this.getPiecePositionValue(tower, key)
        let towerValue = this.getTowerPositionValue(tower, key)
        this.positionData[color].kings += +(king || 0)
        this.positionData[color].pieces += king ? 2.2 : pieceValue*.2
        const topV = (king ?  1 : pieceValue * towerValue) * tower[color] *
            (1 + normaFn(tower[color]) * tower[color] / 2)
        this.positionData[color].towersT += topV
        const bottomV = this.bottomTowersValue(topV, tower[oppositeColor(color)], king)
        this.positionData[oppositeColor(color)].towersB += bottomV
        return [topV, bottomV]        
    }

    advantageInNumberOfMoves = ()  => {
        const {
            white: {moveNumber: wM, kings: wK}, black: {moveNumber: bM, kings: bK}
        } = this.positionData
        return ((wK + bK < 1) ? 1 : .1) *( 2.2 / (bM) - 2.2 / (wM))
    }

    bottomTowersValue = (tP: number, bP: number, king = false) => {
        if (!king) {
            return bP * (.6 / tP)
        } else {
            return bP * (.2 / tP)
        }
    }

    advantageInTowers = () => {
        const {white, black} = this.positionData
        const tFactorW = 1 + normaFn(white.pieces - black.pieces) / 8
        const tFactorB = 1 - normaFn(white.pieces - black.pieces) / 8
        const whiteT =(white.towersT + white.towersB) * tFactorW
        const blackT = (black.towersT + black.towersB) * tFactorB
        return whiteT - blackT 
    }

    materailAdvantage = () => {
        const {
            white: {pieces: wP, towersT: wT, towersB: wB}, 
            black: {pieces: bP, towersT: bT, towersB: bB}
        } = this.positionData
        const wM = wP + wT
        const bM = bP + bT
        this.positionData.white.value = (wM + (wM ? wB : 0)) * (wM + .1) / (.1 + bM)
        this.positionData.black.value = (bM + (bM ? bB : 0))
        return this.positionData.white.value - this.positionData.black.value 
    }

    advantageInPieces = () => {
        const {
            white: {kings: wK, pieces: wP}, 
            black: {kings: bK, pieces: bP}
        } = this.positionData
        const wKingsCorr = wK ? (5 - bP) / 8 : 0
        const bKingsCorr = bK ? (5 - wP) / 8 : 0
        return (wP + wKingsCorr - bP - bKingsCorr) * 1.2
    }

    savePieceData = (tower: ITower, key: string) => {
        if (tower.white + tower.black > 1) {
            this.evaluateTower(tower, key)
        } else {
            this.avaluatePiece(tower, key)
        }
    }
}

export default Evaluator
