import axios from 'axios'

import storage from './storage'

import { Config } from '../env'
import { IStoredUser } from '../models/models'

const {token} = storage.getUser() as IStoredUser
     
const defaultAxiosConfig = {
    baseURL: Config[process.env.NODE_ENV || 'development'].baseURL,
    headers: {
        post: {
            'Content-Type': 'application/json',
        },
        common: {
            Authorization: `Bearer ${token}`
        }
    }
}

export const saveUser = (user: IStoredUser, handleError = (e: any) => {}) => {
    storage.saveUser({...user, exp: {...user.exp, onboardingPassed: true}})
    if (user.token) {
        const {games, ratings, token, ...data} = user
        try {
            setAuthorizationHeader(user.token)
            Axios.post('api/user/save', JSON.stringify(data))
        } catch(e: any) {
            console.error(e)
            handleError({msg: e.response?.data?.message || e.message, ok: false})
        }
    }
}

export const Axios = axios.create(defaultAxiosConfig)
export const setAuthorizationHeader = (_token = token) => {
    Axios.defaults.headers.common['Authorization'] = `Bearer ${_token}`
}
