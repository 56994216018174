/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import basicPuzzles from '../../assets/basicPuzzles.json'

import { RoutePaths, GameSettings, BPCs, WPCs } from '../../constants/gameConstants'
import Spinner from '../../UI-components/Spinners/Spinner'
import BGBoard from '../../UI-components/BgBoard/Bg-board'
import { I18n } from '../../assets/i18n'
import { setName } from '../../store/userSlice'
import { AnimatedBoard } from '../../UI-components/Board/AnimatedBoard'
import { selectUserWithProps, selectWithProps } from '../../store/rootState&Reducer';
import { IGameSettings } from '../../models/models';
import { setOpponentType } from '../../store/gameOptionsSlice';
import { FadeIn } from '../../UI-components/FadeIn/FadeIn';
import { ExampleGame } from '../About/About';

import './home.scss'
import { setPuzzles } from '../../store/topStateSlice';
import storage from '../../common/storage';

export const Home = () => {
    const {
        viewMode: mode, theme, name, language, token, exp
    } = useSelector(selectUserWithProps(['viewMode', 'theme', 'name', 'language', 'token', 'exp']))
    const {rivalType} = useSelector(selectWithProps(['rivalType'], GameSettings)) as IGameSettings
    const navi = useNavigate()
    const dispatch = useDispatch()
    const i18n = I18n[language.slice(0, 2)] || I18n.en
    const pCsGame = [BPCs[0], WPCs[0]]
    const pCsPuzzles = [BPCs[5], WPCs[5]]
    const pCsBot = [BPCs[4], WPCs[4]]
    const pCsAnalys = [BPCs[1], WPCs[1]]
    const createGuest = () => {
        const name = `Guest-${Math.round(Math.random()*1000)}`
        storage.saveUser({name})
        dispatch(setName(name))
        dispatch(setPuzzles(basicPuzzles))
    }
    const ShortDesc = () => {
        const desc = (i18n.about_start).split('.')[0]
        return (
            <div className='short-desc'>{desc}.</div>
        )
    }
   
    const Invite = () => {
        useEffect(() => {
            const invite = document.querySelector('.invite_text')
            if (!invite) return 
            invite.innerHTML = i18n.invite
            const clickBtn = (e: any) => {
                e.preventDefault()
                const classList = (e.target as Element).classList
                if (classList.contains('sign-btn')) {
                    navi('auth')
                }
                if (classList.contains('guest-btn')) {
                    createGuest()
                }
            }
            const buttons = invite.querySelectorAll('button')
            buttons.forEach(b => b.addEventListener('click', clickBtn))
            return () => {
                buttons.forEach(b => b.removeEventListener('click', clickBtn))
            }
        }, [])
        return (
            <div className='invite'>
                <ShortDesc />
                <div className='invite_text' />
                <div className="invite_menu">
                    <NavLink className='signin-link' to={RoutePaths.auth} >
                        {i18n.signin || I18n.en.signin}
                    </NavLink>
                    <button 
                        type='button' 
                        className='guest-link' 
                        onClick={createGuest}
                    >
                        {i18n.guest || I18n.en.guest}
                    </button>
                </div>
            </div>
        )
    }
    const resolveGame = (bot = false) => {
        if (bot) {
            rivalType === 'player' && dispatch(setOpponentType('engine'))
        } else if (rivalType === 'engine') {
            dispatch(setOpponentType('player'))
        }
        navi(RoutePaths.game)
    }
    const onbPassed = exp.onboardingPassed
    return (
        <Suspense fallback={<Spinner />}>
            <FadeIn>
            <div className={`page-wrapper noselect${onbPassed ? " onb-passed" : ''}`}>
                <BGBoard />
                <div className={`home-page mode-${mode} theme-${theme}`} >
                    {
                        !name
                        ? <>
                            <h1>{i18n.about_title || i18n.en.about_title}</h1>
                            <div className = 'description-wrapper'>
                                <ExampleGame />
                                <Invite />
                            </div>
                        </>
                        : <div className={`home-menu theme-${theme}`}>
                            {
                                token
                                ? (
                                    <button 
                                        type='button'
                                        className='home-navlink' 
                                        onClick={() => resolveGame()} 
                                    >
                                        <h4>{i18n.playGame}</h4>
                                        <AnimatedBoard num={1} menu={true} pCs={pCsGame} />
                                    </button>
                                )
                                : null
                            }                            
                            <button 
                                type='button'
                                className='home-navlink' 
                                onClick={() => {navi(`../${RoutePaths.puzzles}`)}} >
                                <h4>{i18n.puzzles_home}</h4>
                                <AnimatedBoard num={0} menu={true} pCs={pCsPuzzles} />
                            </button>
                            <button 
                                type='button'
                                className='home-navlink' 
                                onClick={() => resolveGame(true)} >
                                <h4>{i18n.botGame}</h4>
                                <AnimatedBoard num={3} menu={true} pCs={pCsBot} />
                            </button>
                            {
                                token
                                    ? (
                                        <button 
                                            type='button'
                                            className='home-navlink' 
                                            onClick={() => navi(`../${RoutePaths.analysis}`)} 
                                        >
                                            <h4>{i18n.analysisGame}</h4> 
                                            <AnimatedBoard num={2} menu={true} pCs={pCsAnalys} />
                                        </button>
                                    )
                                    : null
                            }
                        </div>
                    }
                </div>
            </div>
            </FadeIn>
        </Suspense>
    )
}

export default Home
