import { 
    BaseCellSize, SideLegendValues, TopLegendValues, BaseBoardSize, 
    PieceCellRatio
} from "../constants/gameConstants"
import { 
    CellsMap,
    EngineBoard,
    IGameBoard,
    INeighborCells,
    IPiecePosition,
    PieceColor as PC,
    IBoardPieces,
    IPieces,
    IBoardTower,
    // PieceType,
    ITower,
    IBoardPiece,
    IChecker,
} from "../models/models"

import {
    getBlackTowersCells,
    getWhiteTowersCells,
} from '../constants/gameConstants'
import { isDev } from "./gameplay-helper-fn"

// import { BoardTower, Tower } from './gameplay-helper-fn'

export const Tower = (props: Partial<ITower>): ITower => {
    const color =  props.color || PC.white
    const tower = {
        ...props,
        color,
        white: props.white || +(color === PC.white),
        black: props.black || +(color === PC.black),
    } as ITower
    return tower
}

export const Piece = (props: Partial<IChecker>): IChecker => {
    const color = props.color || PC.white
    const checker = {...props, color} as IChecker
    return checker
}

export const BoardTower = (props: Partial<IBoardTower>): IBoardTower =>  {
    const color =  props.color || PC.white
    const tower = {
        color,
        white: props.white || +(color === PC.white),
        black: props.black || +(color === PC.black),
        DOM: props.DOM || { x: 0, y: 0 }
    } as ITower
    if (props.king) {
        tower.king = true
    }
    return tower
}

export const BoardPiece = (props: Partial<IBoardPiece>): IBoardPiece =>  {
    const color = props.color || PC.white
    const DOM = props.DOM || { x: 0, y: 0 }
    const checker = {...props, color, DOM} as IChecker
    return checker
}

export const getPlayerColor = (pc: PC | 'random') => {
    if (pc === 'random') {
        return Math.random() < 0.5 ? PC.white : PC.black
    }
    return pc
}

export function getDepth(level: number) {
    return Math.max(Math.floor(level / 5) * 5, level % 5 + 1)
}

export const createStartPosition = (boardSize = BaseBoardSize, GV = 'towers'): IPieces => {
    const towers = {} as IPieces 
    getBlackTowersCells(boardSize).forEach((key: string) => {
        towers[key] = GV === 'towers'
            ? BoardTower({color: PC.black})
            : BoardPiece({color: PC.black}) as IBoardTower
    })
    getWhiteTowersCells(boardSize).forEach((key: string) => {
        towers[key] = GV === 'towers'
            ? BoardTower({color: PC.white})
            : BoardPiece({color: PC.white}) as IBoardTower
    })
    return towers
}

export function removeOutboardTowers(towers: IBoardPieces) {
    const unused = {} as IBoardPieces
    const position = {} as IBoardPieces
    for (const key in towers) {
        if (key.length > 3) {
            unused[key] = towers[key]
        } else {
            position[key] = towers[key]
        }
    }
    return {unused, position}
}


export const getNumbersOfUnusedTowers = (towers: IPieces) => {
    return Object.keys(towers).reduce(
        (acc, key) => {
            acc.white += key.includes('oW') ? 1 : 0
            acc.black += key.includes('oB') ? 1 : 0
            return acc
        },
        { black: 0, white: 0 }
    )
}

export function createOutBoardTowers(
    towers = {} as IBoardPieces,
    bs = 8,
    GV = 'towers'
): IBoardPieces {
    const { usedBlack, usedWhite } = Object.keys(towers).reduce(
        (acc, key) => {
            if (key.length > 4) {
                return acc
            }
            const { 
                white = +(towers[key].color === PC.white), 
                black = +(towers[key].color === PC.black) 
            } = towers[key] || ({white: 0, black: 0} as IBoardTower)
            acc.usedWhite += white
            acc.usedBlack += black
            return acc
        },
        { usedBlack: 0, usedWhite: 0 }
    )
    const totalPieces = bs === 10 ? 20 : 12
    const [unusedBlack, unusedWhite] = [
        totalPieces - usedBlack,
        totalPieces - usedWhite,
    ]
    for (let i = 0; i < unusedBlack; i++) {
        const oBKey = `oB b${i}`
        towers[oBKey] = GV === 'towers' 
            ? BoardTower({color: PC.black})
            : BoardPiece({color: PC.black}) as IBoardTower
        towers[oBKey].DOM = calcPositionOutboardBoxes(oBKey, bs)
    }
    for (let i = 0; i < unusedWhite; i++) {
        const oBKey = `oW w${i}`
        towers[oBKey] = GV === 'towers' 
            ? BoardTower({color: PC.white})
            : BoardPiece({color: PC.white}) as IBoardTower
        towers[oBKey].DOM = calcPositionOutboardBoxes(oBKey, bs)
    }
    return towers
}

export function defineNeighborCells(
    i: number,
    j: number,
    size: number
): INeighborCells {
    const topLegend = TopLegendValues.slice(0, size)
    const sideLegend = SideLegendValues.slice(0, size)
    const neighbors: INeighborCells = {}
    if (i) {
        if (j < size - 1) {
            neighbors.leftUp = `${topLegend[i - 1]}${sideLegend[j + 1]}`
        }
        if (j) {
            neighbors.leftDown = `${topLegend[i - 1]}${sideLegend[j - 1]}`
        }
    }
    if (i < size - 1) {
        if (j < size - 1) {
            neighbors.rightUp = `${topLegend[i + 1]}${sideLegend[j + 1]}`
        }
        if (j) {
            neighbors.rightDown = `${topLegend[i + 1]}${sideLegend[j - 1]}`
        }
    }
    return neighbors
}

export const getDirection = (start: string, end: string) => {
    const [st0, st1] = [start.slice(0, 1), +start.slice(1)]
    const [en0, en1] = [end.slice(0, 1), +end.slice(1)]
    if (st1 > en1) {
        if (st0 > en0) {
            return 'leftDown'
        }
        if (st0 < en0) {
            return 'rightDown'
        }
    } else {
        if (st0 > en0) {
            return 'leftUp'
        }
        if (st0 < en0) {
            return 'rightUp'
        }
    }
    console.error('invalid props to detect diagonal')
    return ''
}

export const createEngineBoard = (size: number = BaseBoardSize): EngineBoard => {
    const GameBoard: EngineBoard = {}
    for (let i = 0; i < size; i++) {
        for (let j = 0; j < size; j++) {
            if ((i + j + 1) % 2) {
                const key = `${TopLegendValues[i]}${SideLegendValues[j]}`
                GameBoard[key] = {
                    boardKey: key,
                    neighbors: defineNeighborCells(i, j, size),
                }
            }
        }
    }
    return GameBoard
}

export function determineCellPosition(
    key: string,
    cellSize: number,
    boardSize: number,
    reversed = false,
) {
    const topInd = reversed
        ? TopLegendValues.slice(0, boardSize).reverse()
        : TopLegendValues.slice(0, boardSize)
    const sideInd = reversed
        ? SideLegendValues.slice(0, boardSize)
        : SideLegendValues.slice(0, boardSize).reverse()
    const y = sideInd.indexOf(parseInt(key.slice(1))) * cellSize
    const x = topInd.indexOf(key[0]) * cellSize
    if (x < 0 || y < 0) isDev() && console.error(x, y, boardSize)
    return { x, y }
}

export function createCellsMap(
    boardSize: number,
    cellSize = BaseCellSize,
    reversed = false,
) {
    const map = {} as CellsMap
    const boardWithoutTowers = createEngineBoard(boardSize)
    for (const key in boardWithoutTowers) {
        map[key] = determineCellPosition(key, cellSize, boardSize, reversed)
    }
    // console.warn(map, boardSize)
    return map
}

export const updateCellsMap = (board: Partial<IGameBoard>) => {
    const {cellSize, cellsMap, boardSize: bs, reversedBoard: reversed} = board
    const newMap = {} as CellsMap
    for (const key in cellsMap) {
        newMap[key] = determineCellPosition(
            key,
            cellSize!,
            bs!,
            reversed!,
        )
    }
    return newMap
}

export const calcPositionOutboardBoxes = (key: string, bs = 8) => {
    const {x: bX = 0, y: bY = 0, width: bWidth = 0} = 
        document.querySelector('.board__body')?.getBoundingClientRect() || {}
    const num = parseInt(key.split(' ')[1].slice(1))
    const middle = Math.floor(num / 2)
    const div = (num - middle) || 0
    const whiteBox = document.querySelector('.pieces-box.white-box')
    const blackBox = document.querySelector('.pieces-box.black-box')
    if (!whiteBox || !blackBox || !bWidth) {
        return { x: 0, y: 0 }
    }
    const { x, y, height, width } = key.includes('oW')
        ? whiteBox.getBoundingClientRect()
        : blackBox.getBoundingClientRect()
    const pieceSize = bWidth * PieceCellRatio / BaseBoardSize
    return bs === 8 
        ? { 
            x: x - bX + width / 2 - pieceSize / 2, 
            y: y - bY + height / 2 - pieceSize / 2 - div + 5
        }
        : {
            x: x - bX + width / 2 - pieceSize / 2 + 5, 
            y: y - bY + height / 2 - pieceSize / 2 - div + 10
        }
}

export const createDemoCellsMap = (cS: number, bs = BaseBoardSize) => {
    // console.log('crate demo cells MAP')
    const cM = createCellsMap(bs, cS)
    return {cS, cM}
}

export const createDemoStartPosition = (cS: number, bs = BaseBoardSize) => {
    
    const {cM} = createDemoCellsMap(cS, bs)
    const stPos = createStartPosition(bs)
    const position = updatePiecesPosition(stPos, cM, cS)
    // console.warn('create demo start pos', cS, bs, position, cM)
    return {cS, cM, position}
}

export const updateDemoStartPosition = (
    pos: IBoardPieces, cM: CellsMap, cS: number, bs = BaseBoardSize
) => {
    
    const position = updatePiecesPosition(pos, cM, cS)
    // console.warn('update demo pos', cS, bs, position)
    return {cS, cM, position}
}

export const updatePiecesPosition = (
    position: IBoardPieces, 
    map: CellsMap, 
    cellSize: number,
    bs = 8,
    excides = [] as string[]
): IBoardPieces => {
    const pieces = {} as IBoardPieces
    // console.warn(bs)
    for (const key in position) {
        pieces[key] = {...position[key]}
        if (!excides.includes(key)) {
            pieces[key].DOM = calcPiecePosition(key, map, cellSize, bs)
        }
    }
    return pieces
}

export const getFreeCells = (cellsMap: CellsMap, pos: IBoardPieces) => {
    const freeC = {} as CellsMap
    for (const key in cellsMap) {
        if (!pos[key]) {
            freeC[key] = cellsMap[key]
        }
    }
    return freeC
}

export const isValidPos = (pos: IBoardPieces) => {
    let white = false, black = false
    for (const key in pos) {
        if (key.length <= 3) {
            white = white || (pos[key].color === PC.white)
            black = black || (pos[key].color === PC.black)
            if (white && black) return true
        }
    }
    return white && black
}

export const calcPiecePosition = (
    key: string,
    cellsMap: CellsMap,
    cellSize: number,
    bs = 8
): IPiecePosition => {
    const outboardTower = key.includes('oB') || key.includes('oW')
    if (outboardTower) {
        return calcPositionOutboardBoxes(key, bs)
    }
    const { x, y } = cellsMap[key] || { x: 0, y: 0 }
    if (!x && !y) {
        // console.error(x, y, cellsMap, key)
    }
    const width = .8 * cellSize
    return {
        x: Math.round(x - width / 2 + cellSize / 2),
        y: Math.round(y - width / 2 + cellSize / 2),
    }
}
