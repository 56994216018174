import { ClockInterval } from "../constants/gameConstants"
import { oppositeColor } from "../local-engine/gameplay-helper-fn"
import { PieceColor } from "../models/models"
import { Observable } from "./observable"

let int: any

const RunOut = ' run-out'

export class Clocks {
    white = 0
    black = 0
    whiteL = 0
    blackL = 0
    whiteClass = 'white-time'
    blackClass = 'black-time'
    $white = new Observable(this.white)
    $black = new Observable(this.black)
    $blackClass = new Observable(this.blackClass)
    $whiteClass = new Observable(this.whiteClass)
    current = "" as PieceColor
    confirmed = false
    adds = 0

    setProps = (props: {white: number, black: number, adds: number}) => {
        this.white = props.white
        this.black = props.black
        this.whiteL = props.white
        this.blackL = props.black
        this.adds = props.adds
        this.confirmed = false
        this.current = "" as PieceColor
    }

    stopGame = () => {
        clearInterval(int)
        this.whiteClass = 'white-time'
        this.$whiteClass.emit(this.whiteClass)
        this.blackClass = 'blackClass'
        this.$blackClass.emit(this.blackClass)
    }

    startClock = (clr: PieceColor) => {
        this.current = clr
        clearInterval(int)
        int = setInterval(() => {
            if (this[clr] > 0) {
                this[clr] -= 1
                this[`$${clr}`].emit(this[clr])
            } else {
                clearInterval(int)
                this.updateClass(clr)
            }
            this.checkClass(clr)
        }, ClockInterval)
    }


    switchClock = () => {
        const oldTurn = this.current
        this[oldTurn] += this.adds
        this[`$${oldTurn}`].emit(this[oldTurn])
        if (!this.confirmed && oldTurn === PieceColor.black) {
            this.confirmGame()
        }
        if (!this.confirmed && oldTurn === PieceColor.white && this.whiteClass.includes(RunOut)) {
            this.updateClass(PieceColor.white)
        }
        this.startClock(oppositeColor(oldTurn))
    }

    confirmGame = () => {
        this.confirmed = true
        if (this.blackClass.includes(RunOut)) {
            this.updateClass(PieceColor.black)
        }
    }

    updateClass = (color: PieceColor, out = false) => {
        this[`${color}Class`] = `${color}-time${out ? RunOut : ''}`
        this[`$${color}Class`].emit(this[`${color}Class`])
    }

    checkClass = (color: PieceColor) => {     
        if ((this.confirmed && this[color] < 15 && this[color] > 0)
            || (!this.confirmed && this[`${color}L`] - this[color] > 10)) {
            this.updateClass(color, true)
        }
    }

    setClock = (cl: PieceColor, time: number) => {
        this[cl] = time
        this[`$${cl}`].emit(this[cl])
    }
}

const clocks = new Clocks()

export default clocks
