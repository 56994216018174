/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react'

import { isDev, oppositeColor } from './gameplay-helper-fn'
import { useDispatch, useSelector } from 'react-redux'
import { AppType, GameResult, IAnalysisState } from '../models/models'
import { makeMove, setHintMove } from '../store/gameSlice'
import { endGame } from '../store/gameStateSlice'
import { setBestMoveLines } from '../store/analysisSlice'
import { 
    selectBoardWithProps, 
    selectGameOptionsWithProps, 
    selectGameStateWithProps, 
    selectGameWithProps, 
    selectPositionWithoutDom, 
    selectWithProps 
} from '../store/rootState&Reducer'
import { Analysis, TopState } from '../constants/gameConstants'

let enWorker = null as any

export const AIEngine = () =>  {
    const dispatch = useDispatch()
    const {boardSize: size} = useSelector(selectBoardWithProps(['boardSize']))
    const {
        rivalLevel, gameVariant: GV, rivalType
    } = useSelector(selectGameOptionsWithProps(['rivalLevel', 'gameVariant', "rivalType"]))
    const {
        turn, lastMove, moves
    } = useSelector(selectGameWithProps(['turn', 'lastMove', 'moves']))
    const position = useSelector(selectPositionWithoutDom)
    const {
        playerColor, gameStarted
    } = useSelector(selectGameStateWithProps(['playerColor', 'gameStarted']))
    const {
        settingPosition, evalDepth
    } = useSelector(selectWithProps(['settingPosition', 'evalDepth'], Analysis)) as IAnalysisState
    const {type} = useSelector(selectWithProps(['type'], TopState))
    const game = type === 'game'
  
    useEffect(() => {        
        isDev() && console.warn('engine will mount', game, settingPosition)
        enWorker = new Worker(new URL('./full-engine.js', import.meta.url))
        enWorker.onmessage = (e: {data: {type: string, data: Object}}) => {
            proccessMessage(e.data)
        }
        const SetupProps = {
            engineColor: game ? oppositeColor(playerColor) : turn,
            engineLevel: game ? rivalLevel : evalDepth,
            position,
            turn,
            GV,
            size,
            game,
        }
        isDev() && console.log("set engine", SetupProps, enWorker?.postMessage)
        enWorker.postMessage({type: 'setup', data: SetupProps})
        return () => {
            enWorker.terminate()
        }
    }, [])

    useEffect(() => {
        // console.log('lastmove',  lastMove, turn !== playerColor && gameStarted)
        if ((game && rivalType === 'player') || (!game && settingPosition)) {
            enWorker.terminate()
            return
        }
        isDev() && console.log('engine turn', turn)
        if (!settingPosition && type === AppType.analysis) {
            isDev() && console.warn('evaluation turn', lastMove)
            const move = lastMove.turn 
                ? (moves[lastMove.num] && moves[lastMove.num][lastMove.turn])?.move 
                : ''
            enWorker.postMessage({
                type: 'update', 
                data: {move, position, turn} })
            return
        }
        if (turn !== playerColor && gameStarted) {
            if (!moves.length || !lastMove.turn) {
                isDev() && console.log('first')
                enWorker.postMessage({type: 'firstMove', data: position})
                return
            } 
            const move = moves[lastMove.num][lastMove.turn]!.move
            const data = {move, ...lastMove, position}
            enWorker.postMessage({type: 'move', data})
        }
    }, [lastMove, gameStarted])

    const proccessMessage = (props: {type: string, data: any}) => {
        // isDev() && console.log(props.type, props.data, playerColor)
        switch (props.type) {
            case 'move': {
                dispatch(makeMove({...props.data}))
                break
            }
            case 'hint': {
                dispatch(setHintMove(props.data))
                break
            }
            case 'endGame': {
                const result = GameResult[playerColor]
                dispatch(endGame({game: {result}}))
                break
            }
            case 'moveLines': {
                // console.log(props.data)
                dispatch(setBestMoveLines(props.data))
                break
            }
            default: 
                break
        }
    }
    return <></>
}

export default AIEngine
