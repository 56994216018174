

export const updateStoredUser = (storedU, defaultU) => {
    for (const key in storedU) {
        if (!defaultU[key]) {
            delete storedU[key]
        }
    }
    for (const key in defaultU) {
        if (!storedU[key]) {
           storedU[key] = defaultU[key]
        }
    }
}

export const convertHistory = (hist) => {
    const history = hist.split('_')
    const res = []
    let move = []
    for (let i = 0; i < history.length; i++) {
        move.push(history[i])
        if (i % 2) {
            res.push(move)
            move = []
        }
    }
    return res
}

// export const convertHistoryToMoves = (hist, size, GV) => {
//     const mr = new MoveResolver({GV, size})
//     const startPos = updatePiecesPosition(createStartPosition(size, GV))
//     const history = hist.split('_')
//     const moves = []
//     const step = []
//     for (let i = 0; i < history.length; i++) {
//         const move = history[i]
//         const prevPos = !i ? startPos : (moves[i - 1].black || moves[i - 1].white || step.white).position
//         const position = mr.makeMoves([move], prevPos)
//         step[(i % 2 ? 'black' :  'white')] = {move, position}
//         if (i % 2) {
//             moves.push(step)
//         }
//     }
//     return moves
// }


