import { createSlice } from "@reduxjs/toolkit";
import { IGameSettings, IRootState } from "../models/models";

export const InitialGameOptions: IGameSettings = {
    gameVariant: 'towers',
    timing: {gameTimeLimit: 5, adds: 0},
    selectedColor: 'random',
    rivalType: 'player',
    rivalLevel: 0,
    competitionType: 'casual'
}

const gameOptionsSlice = createSlice({
    name: 'gameOptions',
    initialState: InitialGameOptions,
    reducers: {
        setPlayerColor(state, action) {state.selectedColor = action.payload},
        updateOptions(state, action) {return {...state, ...action.payload}},
        setRivalLevel(state, action) {state.rivalLevel = action.payload},
        setOpponentType(state, action) {state.rivalType = action.payload},
        setTiming(state, action) {state.timing = action.payload},
        setGameVariant(state, action) {state.gameVariant = action.payload.GV},
        setCompetitionType(state, action) {state.competitionType = action.payload}
    }
})

export const {
    setGameVariant,
    setOpponentType,
    setPlayerColor,
    setRivalLevel,
    setTiming,
    setCompetitionType,
    updateOptions
} = gameOptionsSlice.actions

export const actions = gameOptionsSlice.actions
export const selectGV = (state: IRootState) => state.gameSettings.gameVariant
export default gameOptionsSlice.reducer
