import { createSlice } from "@reduxjs/toolkit";
import { IRootState } from "../models/models";
import storage, { DefaultUser } from "../common/storage";

const user = storage.getUser() || {}


export const InitialUserState =  {...DefaultUser, ...user}

const userSlice = createSlice({
    name: 'user',
    initialState: InitialUserState,
    reducers: {
        loginSucceed(state, action) { return {...state, ...action.payload} },
        logout(state) {state = DefaultUser},
        setLanguage(state, action) {state.language = action.payload},
        saveUser(state, action) {
            return {...state, ...action.payload}
        },
        updateResolved(state, action) {
            if (action.payload) {
                state.resolvedPuzzles?.push(action.payload)
            }
        },
        close() {},
        setPiecesHighlight(state, action) {state.highlightPieces = action.payload},
        allowPremove(state, action) {state.premoveAllowed = action.payload},
        setBoardNotation(state, action) {state.boardNotation = action.payload},
        setView(state, action) {state.towerView = action.payload},
        setArrowHighlight(state,action) {state.highlightArrow = action.payload},
        setNextHighlight(state, action) {state.highlightNext = action.payload},
        setLastHighlight(state, action) {state.highlightLast = action.payload},
        followPlayer(state, action) {state.friends.push(action.payload)},
        blockPlayer(state, action) {state.blocked.push(action.payload)},
        setExp(state, action) {state.exp = action.payload},
        updateExp(state, action) {state.exp = {...state.exp, ...action.payload}},
        setName(state, action) {state.name = action.payload},
        setToken(state, action) {state.token = action.payload},
        saveName(state, action) {state.name = action.payload},
        setPieceColor(state, action) {state.pieceColors = action.payload},
        setVolume(state, action) {state.volume = action.payload},
        setTheme(state, action) {state.theme =  action.payload},
        setViewMode(state, action) {state.viewMode = action.payload},
        setRatings(state, action) {state.ratings = action.payload},
        clearResolved(state) {state.resolvedPuzzles = []; storage.saveUser({resolvedPuzzles: []})},
        checkStorage() {}
    }
})

export const {actions} = userSlice
export const selectLanguage = (state: IRootState) => state.user.language
export const selectToken = (state: IRootState) => state.user.token
export const selectTheme = (state: IRootState) => state.user.theme
export const selectName = (state: IRootState) => state.user.name
export const selectUser = (state: IRootState) => state.user
export const selectMode = (state: IRootState) => state.user.viewMode

export default userSlice.reducer

export const {
    logout,
    close,
    setName,
    loginSucceed,
    setLanguage,
    saveUser,
    checkStorage,
    setVolume,
    setRatings,
    setViewMode,
    setTheme,
    setPieceColor,
    saveName,
    setToken,
    setExp,
    updateExp,
    blockPlayer,
    setPiecesHighlight,
    followPlayer,
    updateResolved,
    clearResolved,
    setArrowHighlight,
    setNextHighlight,
    setLastHighlight,
    setBoardNotation,
    setView,
    allowPremove
} = userSlice.actions
