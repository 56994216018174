import React, { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import AuthPage from './Pages/Auth/AuthPage'
import Home from './Pages/Home/Home' 
import Spinner from './UI-components/Spinners/Spinner'
import { RoutePaths } from './constants/gameConstants'
import GamePage from './Pages/Game/GamePage'
import SignupPage from './Pages/Auth/SignupPage'
import SendEmailPage from './Pages/Auth/SendEmail'
import ResetPage from './Pages/Auth/ResetPassPage'
import { Puzzles } from './Pages/Puzzles/Puzzles'
import { Puzzle } from './Pages/Puzzles/Puzzle'
import { selectUserWithProps } from './store/rootState&Reducer'
import SettingPage from './Pages/Settings/Settings'
import About from './Pages/About/About'

// const About = lazy(() => import())
const GameAnalizePage =  lazy(() => import( './Pages/Analysis/AnalysisPage'));
const FeedbackPage = lazy(() => import( './Pages/Feedback/Feedback'))
const GameWithAI = lazy(() => import( './Pages/Game/GameVsAI'));
const GameWithOtherPlayer = lazy(() => import( './Pages/Game/GameVsOtherPlayer'));
const PresetGamePage = lazy(() => import( './Pages/Game/GamePreset/PresetGamePage'));
const ProfilePage = lazy(() => import( './Pages/Profile/Profile'));


const AppRouter = () => {
    const {token, exp: {onboardingPassed}} = useSelector(selectUserWithProps(['token', 'exp']))
    const isAuth = !!token
    return (
        <Suspense fallback={<Spinner />}>
            <Routes>
                <Route path={RoutePaths.home} element={<Home />} />
                {
                    onboardingPassed
                    ? <>
                        <Route path={RoutePaths.about} element={<About />} ></Route>
                        <Route path={RoutePaths.puzzles} element={<Puzzles />} >
                            <Route path={RoutePaths.puzzle} element={<Puzzle />} />
                        </Route>
                        <Route path={RoutePaths.game} element={<GamePage />} >
                            <Route path={RoutePaths.gameOnline} element={<GameWithOtherPlayer />} />
                            <Route path={RoutePaths.gameOfline} element={<GameWithAI />} />
                            <Route path={RoutePaths.presets} element={<PresetGamePage />} />
                        </Route>
                        <Route path={RoutePaths.settings} element={<SettingPage />} />
                        <Route path={RoutePaths.feedback} element={<FeedbackPage />} />
                        <Route path={RoutePaths.profile} element={<ProfilePage />} />
                    </>
                    : null
                }                
                {isAuth 
                    ? <Route 
                            path={RoutePaths.analysis} 
                            element= {<GameAnalizePage />}
                        />
                    : <Route path="*" element={<Navigate to="/" replace />} />
                } 
                <Route path={RoutePaths.auth} element={<AuthPage />}>
                    <Route path={RoutePaths.signup} element={<SignupPage />} />
                    <Route path={RoutePaths.email} element={<SendEmailPage />} />
                    <Route path={RoutePaths.reset} element={<ResetPage />} />
                </Route>
                <Route path="*" element={<Navigate to='/' replace />} />
            </Routes>
        </Suspense> 
    )    
}

export default React.memo(AppRouter)
