
import { useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Axios } from "../../common/axios"
import { I18n } from "../../assets/i18n"
import { useMessageShow } from "../../hooks/useShowMessage"
import { IRef } from "../../models/models"
import { selectLanguage, selectMode, selectTheme } from "../../store/userSlice"
import { isDev } from "../../local-engine/gameplay-helper-fn"
import { Input } from "../../UI-components/Input/Input"
import { FadeIn } from "../../UI-components/FadeIn/FadeIn"

export const SendEmailPage: React.FC = () => {
    const [form, setForm] = useState({email: ''})
    const submitted = useRef(false)
    const mode = useSelector(selectMode)
    const theme = useSelector(selectTheme)
    const formRef: IRef<any> = useRef()
    const {showMessage} = useMessageShow()
    const language = useSelector(selectLanguage)
    const navigate = useNavigate()
    // const dispatch = useDispatch()
    const i18n = I18n[language.slice(0, 2)] || I18n.en

    const handleSendEmail = async () => {
        const reqData = {email: form.email}
        const reqBody = JSON.stringify(reqData)
        submitted.current = true
        try {
            const data = await Axios.post('api/auth/forgot', reqBody)
            isDev() && console.log(data)
            
            if (data.status === 200) {
                const msg = i18n.newPasswordOk
                showMessage({msg, ok: true})
            } else {
                const {status, data: {message}} = data
                showMessage({msg: `status: ${status}, msg: ${message}`, ok: false})
            }
        } catch(e: any) {
            showMessage({msg: e.message, ok: false})
        } finally {
            submitted.current = false
        }
    }

    const handleChanges = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault()
        const target = e.target as HTMLInputElement
        if (target.value) {
            target.parentElement!.classList.add('dirty')
        } else {
            target.parentElement!.classList.remove('dirty')
        }
        setForm(form => ({...form, [target.name]: target.value}))
    }
    
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (submitted.current) return
        handleSendEmail()
    }
    
    const redirectSignin = () => {
        navigate(`..`)
    }

    return (
        <FadeIn>
        <div className={`auth-page mode-${mode} theme-${theme} noselct` } >
            <div className="form-wrapper " >
                <div className="form-title-wrapper">
                    {/* <h1>Welcome Tower Checkers</h1> */}
                    <h2>{i18n.emailForPassword}</h2>
                </div>
                <form onSubmit={handleSubmit} ref={formRef} >
                    <Input 
                        id='email'
                        type="email"
                        name="email"
                        value={form.email}
                        label={'E-mail'}
                        handleChanges={handleChanges}
                    />
                    <button type="submit" value="sendEmail" className="submit-btn">{i18n.sendEmail || 'send new password'}</button>
                    <div>
                        <button type="button" 
                            onClick={redirectSignin} 
                            className="to-sign-in-btn"
                        >
                            {i18n.modeButtonReg}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        </FadeIn>
    )
}

export default SendEmailPage
