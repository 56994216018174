import {call, delay, put, select, takeLatest} from 'redux-saga/effects'
import { UnknownAction } from 'redux'

import basicPuzzles from '../../assets/basicPuzzles.json' 
import {
    InitialAnalysisState, 
    setPosition, 
    updateAnalysisState,
} from '../analysisSlice'
import {moveR} from '../../local-engine/move-r'
import { 
    InitialGame, 
    setLastMove, 
    updateGame,
    updatePieces,
    updatePosition,
} from '../gameSlice'
import { 
    getPuzzles, 
    setAppType, 
    setBackMessage, 
    setPuzzles, 
    choosePuzzle,
    setAppState,
    startEngine,
    savePuzzle,
    showAds,
    setSolution,
    getNewPuzzles,
    setPuzzleStatus,
} from '../topStateSlice'
import { Axios, setAuthorizationHeader } from '../../common/axios'
import { selectPuzzle } from '../rootState&Reducer'
import { setPremove, setTouchedTower, updateBoardState } from '../boardSlice'
import { 
    createCellsMap,
    createOutBoardTowers, getNumbersOfUnusedTowers, removeOutboardTowers, updatePiecesPosition 
} from '../../local-engine/board-helper-fn'
import { 
    addKings,
    copyObj, 
    filterAndSort, 
    isDev, 
    movesFromSolution, 
    oppositeColor, 
    parsePuzzles,
    positionWithoutDom, 
    removeMandatory
} from '../../local-engine/gameplay-helper-fn'
import { 
    IRootState, IPuzzle, IBoardPieces, PieceColor, AppType, GameResult, GameVar, 
    GameVariant,
    ITopState,
    BoardNotation
} from '../../models/models'
import storage from '../../common/storage'
import { endGame, setGameState, InitialGameState, setGameKey } from '../gameStateSlice'
import { TwelveH } from '../../constants/gameConstants'
import { setBoardNotation, setView } from '../userSlice'
import { updateOptions } from '../gameOptionsSlice'


function* workerPuzzle(action: UnknownAction) {
    const {
        user: {token, userId},
        analysis: {bestMoveLines},
        game: {moves, startPosition, startTurn},
        gameSettings: {gameVariant: GV},
        board: {boardSize},
        topState: {selectedPuzzle}
    } = (yield select()) as IRootState
    const {description, level} = action.payload as any
    const BMLLength = bestMoveLines[0]?.line?.length || 0
    const solution = moves.length && moves.length * 2 - 1 > BMLLength
        ? moves.reduce((acc: string[], m: any, i:number) => {
            if (startTurn === PieceColor.white || i) {
                acc.push(m.white.move)
            }
            if (m.black) {
                acc.push(m.black.move)
            }
            return acc
        }, [] as string[]).join('_')
        : bestMoveLines[0].line.join('_')
    const payload = {
        position: JSON.stringify(positionWithoutDom(startPosition)), 
        gType: GV.slice(0, 3),
        turn: startTurn,
        solution,
        author: userId,
        bSize: boardSize,
        description,
        level
    } as IPuzzle
    if (bestMoveLines[1] && bestMoveLines[1].value === bestMoveLines[0].value && !moves.length) {
        payload.eSolution = bestMoveLines[1].line.join('_')
    }
    isDev() && console.log(payload)
    setAuthorizationHeader(token)
    try {
        if (!selectedPuzzle) {
            yield call(Axios.post, '/api/puzzles/create', payload)
        } else {
            (payload as any)._id = selectedPuzzle
            yield call(Axios.post, '/api/puzzles/update', payload)
            yield put(choosePuzzle(''))
        }
    }
    catch(e: any) {
        console.error(e)
        const mess = {msg: e.response?.data?.message || e.message, ok: false}
        yield put(setBackMessage(mess))
    }
}

function* workerChoosePuzzle(action: UnknownAction) {
    const {
        topState: {solution: sol, puzzleResolved, eSolution: eSol, type, puzzles},
        board: {towerTouched, boardSize, cellSize: cS},
        game: {lastMove},
        user: {towerView, boardNotation}
} = (yield select()) as IRootState
    if (type !== AppType.analysis) {
        yield put(startEngine(false))
    }
    if (puzzleResolved) {
        yield put(setPuzzleStatus(null))
    }
    if (!action.payload) {
        if (sol?.length || eSol?.length) {
            yield put(setSolution([]))
        }
        if (towerTouched) {
            yield put(setTouchedTower(null))
        }
        if (lastMove.turn) {
            yield put(setLastMove({}))
        }
        return
    }
    yield delay(100)
    const puzzle = (yield select(selectPuzzle)) as IPuzzle
    if (!puzzle) {
        isDev() && console.error('no puzzle', action, puzzles)
        return
    }
    const {turn, solution, position: pos, bSize, gType, eSolution = []} = puzzle
    const GV = GameVar[gType as 'tow'].toLowerCase()  as GameVariant
    moveR.setProps({GV, size: bSize})
    if (GV === 'towers' && towerView !== 'face') { 
        yield put(setView('face'))
    }
    if (GV === 'international' && boardNotation !== BoardNotation.dr) {
        yield put(setBoardNotation(BoardNotation.dr))
    }
    if (GV !== 'international' && boardNotation === BoardNotation.dr) {
        yield put(setBoardNotation(BoardNotation.ch))
    }
    const cellSize = boardSize !== bSize ? Math.round(cS * boardSize/bSize/10)*10  : cS
    yield put(updateOptions({gameVariant: GV}))
    const reversedBoard = turn === PieceColor.black
    const cellsMap = createCellsMap(bSize, cellSize, reversedBoard)
    let position = updatePiecesPosition(addKings(pos as IBoardPieces), cellsMap, cellSize)
    yield put(updateBoardState({cellSize, cellsMap, reversedBoard, boardSize: bSize}))
    yield put(updateAnalysisState({...InitialAnalysisState}))
    const topProps = {
        solution: movesFromSolution(solution as string[], position, moveR),
        puzzleResolved: null
    } as ITopState
    if (eSolution.length) {
        topProps.eSolution = movesFromSolution(eSolution as string[], position, moveR)
    }
    yield put(setAppState(topProps))
    const nextMoves = moveR.getPossibleMoves(position, turn, true)
    if (nextMoves[0].takenPieces?.length) {
        const posWithMand = copyObj(position)
        for (const move of nextMoves) {
            const key = move.move[0]
            posWithMand[key].mandatory = true
        }
        position = posWithMand
    }
    yield put(updateGame({
        ...InitialGame, position, startPosition: position, nextMoves, turn, startTurn: turn
    }))
}

export function* workerNewPuzzles() {
    const { 
        user: {resolvedPuzzles = [], token},
        gameSettings: {gameVariant: GV}
    } = (yield select()) as IRootState
    if (!token || Date.now() - storage.getRequestDate() < TwelveH) { return }
    let storedPuzzles = storage.getPuzzles(), newPuzzles
    try {
        setAuthorizationHeader(token)
        const url = `/api/puzzles/new?after=${storage.getRequestDate()}`
        const res: {data: any} = yield call(Axios.get, url)
        newPuzzles = parsePuzzles(res.data.puzzles)
        if (newPuzzles.length) { 
            storedPuzzles = storedPuzzles.concat(newPuzzles)
            storage.savePuzzles(storedPuzzles)
            storage.setRequestDate()
        }
    } catch(e: any) {
        console.error(e)
        const mess = {msg: e.response?.data?.message || e.message, ok: false}
        yield put(setBackMessage(mess))
    } finally {       
        yield put(setPuzzles(filterAndSort(storedPuzzles, resolvedPuzzles, GV).slice(0, 6)))
    }
}

export function* workerGetPuzzles() {
    const {
        user: {
            token,
            resolvedPuzzles = [],
            exp: {onboardingPassed}
        },
        gameSettings: {gameVariant: GV},
         topState: {puzzles}
    } = (yield select()) as IRootState
    let storedPuzzles = storage.getPuzzles()
    if (!storedPuzzles.length && !puzzles.length && !token) {
        yield put(setPuzzles(filterAndSort(
            basicPuzzles as unknown as IPuzzle[], resolvedPuzzles, GV).slice(0, 6)
        ))
        return
    }
    if (puzzles.length && Date.now() - storage.getRequestDate() < TwelveH) return
    else if (
        !puzzles.length 
        && storedPuzzles.length 
        && Date.now() - storage.getRequestDate() < TwelveH
    ) {
        yield put(setPuzzles(filterAndSort(storedPuzzles, resolvedPuzzles, GV).slice(0, 6)))
        return
    }
    if (storedPuzzles.length) {
        yield workerNewPuzzles()
        return
    }
    try {
        setAuthorizationHeader(token)
        const url = `/api/puzzles`
        const res: {data: any} = yield call(Axios.get, url)
        const newPuzzles = parsePuzzles(res.data.puzzles) as IPuzzle[]
        const baseUnresolved = basicPuzzles
            .filter(p => !resolvedPuzzles.includes(p._id)) as unknown as IPuzzle[]
        storedPuzzles = baseUnresolved.concat(newPuzzles)
        storage.savePuzzles(storedPuzzles)
        storage.setRequestDate()
    } catch(e: any) {
        console.error(e)
        const mess = {msg: e.response?.data?.message || e.message, ok: false}
        yield put(setBackMessage(mess))
    } finally {
        if (onboardingPassed) {
            yield put(setPuzzles(filterAndSort(storedPuzzles, resolvedPuzzles, GV).slice(0, 6)))
        } else {
            yield put(setPuzzles(basicPuzzles))
        }       
    }
}

function* workerAppType(action: UnknownAction) {
    const type = action.payload
    let {
        gameState: {result = null, gameKey, playerColor},
        topState: {ads},
        game: {position},
        board: {towerTouched, premove}
    } = (yield select()) as IRootState
    if (ads) {
        yield put(showAds(false))
    }
    if (gameKey && result && type === AppType.puzzles) {
        yield put(setGameState(InitialGameState))
        yield put(updateGame(InitialGame))
        if (towerTouched) yield put(setTouchedTower(null))
        if (premove) yield put(setPremove(null))
    }
    // if (gameKey && !result && type !== AppType.game) {
       
    //     yield delay(50)
    //     if (type === AppType.puzzles) {
    //         yield put(showAds(false))
    //         yield put(setGameState(InitialGameState))
    //         yield put(updateGame(InitialGame))
    //         if (towerTouched) yield put(setTouchedTower(null))
    //         if (premove) yield put(setPremove(null))
    //     } else {
    //         yield put(setGameKey(''))
    //     }
    // }
    const {
        topState: {localEngine},
        analysis: {settingPosition},
    } = (yield select()) as IRootState
    if (!settingPosition) {
        yield put(setPosition(true))
    }
    if (action.payload !== AppType.game && localEngine) {
        yield put(startEngine(false))
    }
    if (!settingPosition || localEngine) yield delay(50)
    const {
        topState: {puzzles, puzzleResolved}
    } = (yield select()) as IRootState
    switch (type) {
        case AppType.game: {
            if (!gameKey) {
                yield put(updateGame(InitialGame))
                yield put(updateAnalysisState(InitialAnalysisState))
                yield put(setGameState(InitialGameState))
            }
            break
        }
        case AppType.puzzles: {
            if (Object.keys(position).length) {
                yield put(updatePosition({}))
            }
            if (!puzzles.length) {
                yield workerGetPuzzles()
            }
            if (Date.now() - storage.getRequestDate() > TwelveH) {
                yield workerNewPuzzles()
            }
            break
        }
        case AppType.analysis: {
            const {
                board: {cellSize, boardSize},
                game: {position: pos},
            } = (yield select()) as IRootState
            if (puzzleResolved || result) {
                yield put(setPosition(false))
                yield delay(50)
                yield put(puzzleResolved 
                    ? choosePuzzle('') 
                    : setGameState(InitialGameState))
                return
            }
            const board = document.querySelector('.board__body')
            let box = document.querySelector('.pieces-box')
            if (!board 
                || !box 
                || Math.round(board.getBoundingClientRect().width) < cellSize*boardSize) {
                yield delay(750)
                box = document.querySelector('.pieces-box')
                yield put(updatePosition(createOutBoardTowers(copyObj(pos), boardSize)))
                return
            }
            box = document.querySelector('pieces-box')
            yield put(updatePosition(createOutBoardTowers(copyObj(pos), boardSize)))
            break
        }
        default: {
            isDev() && console.log('unexpected app type')
        }
    }
}

function* workerEvaluatePosition(action: UnknownAction) {
    const {
        topState: {localEngine, puzzleResolved, type},
        game: {position: pos, startPosition, moves = []},
        gameState: {result},
        board: {boardSize}
    } = (yield select()) as IRootState
    if (localEngine) { 
        yield put(startEngine(false))
        yield delay(0)
    }
    if (action.payload) {
        const {
            game: {turn: tn, startTurn: stT}
        } = (yield select()) as IRootState
        const unused = getNumbersOfUnusedTowers
        const turn = type === AppType.analysis ? tn : PieceColor.white
        const startTurn = type === AppType.analysis ? turn : stT
        const position = createOutBoardTowers(removeMandatory(pos), boardSize)
        yield put(updateGame({...InitialGame, position, turn, startTurn}))
        yield put(updatePieces(unused))
        yield put(updateAnalysisState({...InitialAnalysisState}))
        return
    }
    const {
        board: {boardSize: size, cellSize, cellsMap},
        game: {startTurn},
        gameSettings: {gameVariant: GV},
    } = (yield select()) as IRootState
    moveR.setProps({size, GV})
    const {unused, position: newPos} = removeOutboardTowers(pos)
    const stPos = (result || puzzleResolved) ? startPosition : newPos
    let position = updatePiecesPosition(stPos, cellsMap, cellSize)
    const nextMoves = moveR.getPossibleMoves(position, startTurn, true)
    if (nextMoves[0]?.takenPieces?.length) {
        const posWithMand = copyObj(position)
        for (const move of nextMoves) {
            const key = move.move[0]
            posWithMand[key].mandatory = true
        }
        position = posWithMand
    }
    yield put(updateGame({
        ...InitialGame,
        position,
        startPosition: position,
        turn: startTurn,
        startTurn,
        moves, 
        nextMoves
    }))
    yield put(updateAnalysisState({
        ...InitialAnalysisState,
        unused: updatePiecesPosition(unused, cellsMap, cellSize),
        gameMoves: moves,
        settingPosition: false
    }))
    yield delay(0)
    yield put(startEngine(true))
}

export default function* watcherPuzzles() {
    yield takeLatest(getPuzzles, workerGetPuzzles)
    yield takeLatest(savePuzzle, workerPuzzle)
    yield takeLatest(choosePuzzle, workerChoosePuzzle)
    yield takeLatest(setPosition, workerEvaluatePosition)
    yield takeLatest(setAppType, workerAppType)
    yield takeLatest(getNewPuzzles, workerNewPuzzles)
}
