import { useSelector } from "react-redux"
import { IRootState } from "../../models/models"


import './connection.scss'

export const ConnectionIndicator = () => {
    const connStatus = useSelector((state: IRootState) => state.topState.wsConnectionState)
    const connArr = new Array(3).fill(0).map((i, j) => {
        if (!connStatus || connStatus === 3) {
            return <span key={j} className="indicator-item error"></span>
        }
        const className=`indicator-item ${connStatus && j <= (4 - connStatus) ? 'fill' : 'empty'}`
        return <span key={j} className={className}></span>
    })
    return (<div 
                className="indicators-container"
                title="connection quality"
            >
                {connArr}
            </div>)
}