import { BoardCl, MPS, TPS } from "../constants/gameConstants";
import { 
    IGame, 
    IBoardPieces, 
    MMRResult, 
    TowerTouched, 
    IBoardTower, 
    PieceColor,
    IBoardPiece, 
    CellsMap, 
    Move,
} from "../models/models";

import { calcPiecePosition, getFreeCells, getNumbersOfUnusedTowers } from "./board-helper-fn";
import { checkMoveTargetCell, copyObj, getPieceMoves, isDev } from "./gameplay-helper-fn";

export interface IHandlerBaseProps {
    event: IHookEvent, 
    position: IBoardPieces
}
export interface IMMoveProps extends IHandlerBaseProps{
    towerTouched: TowerTouched, 
    premove?: any
}

export interface IHandlerProps extends IHandlerBaseProps {
    cellSize: number,
    cellsMap: CellsMap 
    nextMoves: MMRResult[],
    moveStep: number
    turn: PieceColor
    premove?: {from: string, to: string}
}
export interface IMDownProps extends IHandlerProps {
    towerTouched?: TowerTouched
    bs?: number
}

export interface IMUpProps extends IHandlerProps {
    towerTouched: TowerTouched
}

export interface IHookEvent {key: string, x: number, y: number}

export abstract class EventHandlers {
    abstract onMouseDown(props: any): any

    onMouseMove(props: IMMoveProps): any {
        const {
            key,
            startCursorPosition: SCP,
            startTowerPosition: STP,
        } = props.towerTouched
        const { x, y } = props.event
        const towers = copyObj(props.position)
        const piece = towers[key]! as IBoardTower
        if (!SCP || !STP) {
            isDev() && console.error('invalid props', props)
        }
        const newPosition = {
            x: STP.x + x - SCP.x,
            y: STP.y + y - SCP.y,
        }
        const prevPosition = piece.DOM
        const deltaX = Math.abs(prevPosition!.x - newPosition.x)
        const deltaY = Math.abs(prevPosition!.y - newPosition.y)
        if (deltaX + deltaY >= 6) {
            const DOM = newPosition
            return {key, DOM}
        }
        return {}
    }
    abstract onMouseUp(props: any): any
}

export const mergeTowers = (
    t1: string,
    t2: string,
    position: IBoardPieces
) => {
    const towers = copyObj(position)
    const tower = {...towers[t1]}
    tower.white += towers[t2].white
    tower.black += towers[t2].black
    tower.color = towers[t2].color
    tower.type = towers[t2].type
    delete towers[t2]
    towers[t1] = tower
    return {towers}
}

export const getMoveData = (props: IMUpProps) => {
    const {
        towerTouched, cellSize, cellsMap, position: pos, nextMoves, moveStep: step, event: {key}
    } = props
    const from = towerTouched.key
    // let lastStep = false
    let moveSteps = [from, key]
    const fitMoves = nextMoves.filter((m: MMRResult) => {
        return moveSteps[0] === m.move[0] 
            && m.move.slice(step + 1).includes(moveSteps[1]) 
    })
    if (!fitMoves[0]) {
        isDev() && console.error('cant find', key, from, )
    }
    const { endPos, takenPieces, move } = fitMoves[0]
    const index = move.findLastIndex((s: string) => s === key)
    const lastStep = move.length === index + 1 
        || (towerTouched.possibleMoves[key] && from === key)
    // console.log(key, move, index, lastStep, endPos)
    if (!lastStep) {
        const gamePiece = {...pos[from]}
        gamePiece.DOM = calcPiecePosition(key, cellsMap, cellSize)
        return {pieces: {[from]: gamePiece}, moveStep: index, towerTouched: null}
    }
    const position = copyObj(endPos) as IBoardPieces
    for (const k in position) {
        if (k === key) {
            position[k].DOM = calcPiecePosition(k, cellsMap, cellSize)
        }
    }
    const makedMove = {
        move: move.join(takenPieces ? TPS : MPS),
        position,
    } as Move
    return {makedMove, towerTouched: null, moveStep: 0}
}

class TowersGameHandlers extends EventHandlers {

    onMouseUp(props: IMUpProps) {
        if (!props.towerTouched && !props.premove) {
            return {}
        }
        const boardRect = document.querySelector(`.${BoardCl}`)?.getBoundingClientRect() || {} as any
        if (!boardRect.x) return {}
        const {
            towerTouched, cellSize, cellsMap, position: pos, event, premove
        } = props
        if (!towerTouched && premove) {
            const possPremoves = cellsMap
            const to = checkMoveTargetCell(event, possPremoves, cellSize, boardRect)
            if (!to || to === premove.from) return {premove: null}
            return {premove: {...premove, to}}
        }
        const startCell = {[props.towerTouched.key]: cellsMap[props.towerTouched.key]}
        const start = checkMoveTargetCell(event, startCell, cellSize, boardRect)
        if (start) {
            const piece = {...pos[start]}
            piece.DOM = calcPiecePosition(start, cellsMap, cellSize)
            return {towerTouched: {...towerTouched, mouseDown: false}, pieces: {[start]: piece}}
        }
        const possMoves = towerTouched.possibleMoves
        const key = checkMoveTargetCell(event, possMoves, cellSize, boardRect)
        if (!key) {
            const piece = {...pos[towerTouched.key]}
            piece.DOM = calcPiecePosition(towerTouched.key, cellsMap, cellSize)
            return {pieces: {[towerTouched.key]: piece}, moveStep: 0, towerTouched: null}
        }
        const _props = {...props, event: {...event, key}} as IMUpProps
        return getMoveData(_props) 
    }

    onMouseDown(props: IMDownProps) {
        const {
            cellsMap, 
            cellSize, 
            nextMoves,
            moveStep,
            position,
            turn,
            towerTouched,
            event: {key, x, y}
        } = props
        if (!key && !towerTouched) return {}
        if (!key && towerTouched) {
            const boardRect = document.querySelector(`.${BoardCl}`)?.getBoundingClientRect() || {} as any
            if (!boardRect) return {towerTouched: null}
            const possMoves = towerTouched.possibleMoves
            const target = checkMoveTargetCell({x, y}, possMoves, cellSize, boardRect)
            if (!target) return {towerTouched: null}
            const _props = {...props, event: {x, y, key: target}} as IMUpProps
            return getMoveData(_props)
        }
        if (key && towerTouched && towerTouched.key === key && towerTouched.possibleMoves[key]) {
            const _props = {...props, event: {x, y, key}} as IMUpProps
            return getMoveData(_props)
        }
        if (!position[key]) return {}
        if (position[key].color !== turn) {
            return {premove: {from: key}}
        }
        if (key === towerTouched?.key && !towerTouched.possibleMoves[key]) {
            isDev() && console.log(key, towerTouched)
            const piece = {...position[key]}
            piece.DOM = calcPiecePosition(key, cellsMap, cellSize)
            return {pieces: {[key]: piece}, towerTouched: null}
        }
        const { color, king, DOM } = position[key]
        const startCursorPosition = {x, y}
        const possibleMoves = getPieceMoves(cellsMap, {nextMoves, moveStep} as IGame, key)
        return {
            towerTouched: {
                color, 
                king, 
                key, 
                startTowerPosition: DOM || calcPiecePosition(key, cellsMap, cellSize),
                startCursorPosition,
                possibleMoves,
                mouseDown: true
            }
        }
    }  
}

export const gameHandlers = new TowersGameHandlers()

class PieceSetupHandlers extends EventHandlers{
    onMouseDown(props: IMDownProps) {
        const {
            cellsMap, 
            cellSize,
            position,
            event: {key, x, y}
        } = props
        if (!position[key]) {
            return {}
        }
        const { color, king, DOM } = position[key]
        const startCursorPosition = {x, y}
        const possibleMoves = getFreeCells(cellsMap, position)
        return {towerTouched: {
            color, 
            king, 
            key, 
            startTowerPosition: DOM || calcPiecePosition(key, cellsMap, cellSize),
            startCursorPosition,
            possibleMoves,
            mouseDown: true
        }}
    }

    onMouseUp(props: IMUpProps) {
        const {towerTouched, cellSize, cellsMap, event, position} = props
        const { key } = towerTouched
        const boardRect = document.querySelector(`.${BoardCl}`)?.getBoundingClientRect() || {} as any
        if (!boardRect.x) return {
            pieces: {[key]: {...position[key], DOM: calcPiecePosition(key, cellsMap, cellSize)}}
        }
        const possMoves = towerTouched.possibleMoves
        const cellKey = checkMoveTargetCell(event, possMoves, cellSize, boardRect)
        if (!cellKey) return {
            pieces: {[key]: {...position[key], DOM: calcPiecePosition(key, cellsMap, cellSize)}}
        }
        const DOM = calcPiecePosition(cellKey, cellsMap, cellSize)
        let towers = copyObj(position)
        if (key.length > 3) {
            towers[cellKey] = {
                color: towerTouched.color,
                DOM,
            }
            if (towerTouched.king) {
                towers[cellKey].king = true
            }
            delete towers[key]
            return { towers, towerTouched: null as unknown as TowerTouched }
        } else {
            towers[cellKey] = {...towers[key], DOM}
            delete towers[key]
            return { towers, towerTouched: null as unknown as TowerTouched }
        }
    }
}

export const pieceSetupHandlers = new PieceSetupHandlers()

class TowerSetupHandlers extends EventHandlers{
    onMouseDown(props: IMDownProps) {
        const {
            cellsMap, 
            cellSize, 
            position,
            event: {key, x, y}
        } = props
        if (!position[key]) {
            return {}
        }
        const { color, king, DOM } = position[key]
        const startCursorPosition = {x, y}
        const possibleMoves = cellsMap
        return {towerTouched: {
            color, 
            king, 
            key, 
            startTowerPosition: DOM || calcPiecePosition(key, cellsMap, cellSize),
            startCursorPosition,
            possibleMoves,
            mouseDown: true
        }}
    }

    onMouseUp(props: IMUpProps) {
        const {towerTouched, cellSize, position, cellsMap, event} = props
        // const towerTouched = copyObj(towerTouched!) as TowerTouched,
        const boardRect = document.querySelector(`.${BoardCl}`)?.getBoundingClientRect() || {} as any
        const { key } = towerTouched
        if (!boardRect.x) return ({
            pieces: {
                [key]: {...position[key], DOM: calcPiecePosition(key, cellsMap, cellSize)}
            }
        })
        const possMoves = towerTouched.possibleMoves
        const cellKey = checkMoveTargetCell(event, possMoves, cellSize, boardRect)
        const DOM = calcPiecePosition((cellKey || key), cellsMap, cellSize)
        if (!cellKey || cellKey === key) return {
            pieces: {[key]: {...position[key], DOM}}
        }
        let towers = copyObj(position)
        if (position[cellKey] ) {
            return mergeTowers(cellKey, key, towers)
        }
        towers[cellKey] = {
                ...towers[key],
                DOM,
            }
        delete towers[key]
        return { towers, towerTouched: null as unknown as TowerTouched }
    }    
}

export const towerSetupHandlers = new TowerSetupHandlers()

class PieceRemoveHandlers {

    onMouseDown(props: IMDownProps) {
        const {cellsMap, cellSize, bs, position, event: {key}} = props
        let towers = copyObj(position)
        const tower = towers[key] as IBoardPiece
        const colW = tower.color === PieceColor.white
        const num =  Object.keys(towers).filter(i => i.includes(colW ? "oW" : "oB")).length
        const outboardKey = `${colW ? "oW w" : "oB b"}${num}`
        tower.DOM = calcPiecePosition(outboardKey, cellsMap, cellSize, bs)
        towers[outboardKey] = tower
        delete towers[key]
        return {towers}
    }

    onMouseMove(props: any) {
        return {}
    }
    onMouseUp(props: any) {
        return {}
    }
}

class TowerRemoveHandlers {
    onMouseDown(props: IMDownProps) {
        const {cellsMap, cellSize, position, event: {key}} = props
        const towers = copyObj(position) as IBoardPieces
        const { white, black } = towers[key]
        const unusedTowers = getNumbersOfUnusedTowers(towers)
        delete towers[key]
        for (let i = 0; i < white; i++) {
            const key = `oW w${unusedTowers.white + i}`
            const DOM = calcPiecePosition(key, cellsMap, cellSize)
            towers[key] = {
                color: PieceColor.white,
                white: 1,
                black: 0,
                DOM,
            }
        }
        for (let i = 0; i < black; i++) {
            const key = `oB b${unusedTowers.black + i}`
            const DOM = calcPiecePosition(key, cellsMap, cellSize)
            towers[key] = {
                color: PieceColor.black,
                white: 0,
                black: 1,
                DOM,
            }
        }
        return {towers}
    }

    onMouseMove(props: any) {
        return {}
    }

    onMouseUp(props: any) {
        return {}
    }
}

class KingSetHandlers {
    onMouseDown(props: IMDownProps) {
        const {position, event: {key}} = props
        const towers = copyObj(position)
        const tower = towers[key] as IBoardPiece
        if (tower.king) delete tower.king
        else tower.king = true
        return {pieces: {[key]: tower}}
    }

    onMouseMove(props: any) {
        return {}
    }

    onMouseUp(props: any) {
        return {}
    }
}

export const kingSetHandlers = new KingSetHandlers()

export const towerRemoveHandlers = new TowerRemoveHandlers()

export const pieceRemoveHandlers = new PieceRemoveHandlers()
