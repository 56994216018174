import { PuzzleStorageName, RequestDateName, StartPuzzlesDate, StorageName } from "../constants/gameConstants"
import { copyObj } from "../local-engine/gameplay-helper-fn"
import { BoardNotation, IExpirience, IPuzzle, IRegedUser, IStoredUser, PieceColor } from "../models/models"

export const DefaultUser: IRegedUser = {
    _v: require('../../package.json').version,
    name: '',
    userId: '',
    token: '',
    games: [],
    ratings: {
        tow: [{rating: 50, date: new Date()}], 
        rus: [{rating: 50, date: new Date()}], 
        int: [{rating: 50, date: new Date()}]
    },
    resolvedPuzzles: [],
    volume: .1,
    theme: 'sea',
    language: 'en',
    viewMode: 'dark',
    pieceColors: [PieceColor.black, PieceColor.white],
    blocked: [],
    friends: [],
    exp: {
        onboardingPassed: false,
        either: {},
        int: {dRate: 0},
        rus: {dRate: 0},
        tow: {dRate: 0},
    } as IExpirience,
    highlightArrow: true,
    highlightLast: false,
    highlightNext: false,
    towerView: 'face',
    boardNotation: BoardNotation.ch,
    premoveAllowed: true,
    highlightPieces: false,
}

class Storage {
    
    getUser() {
        let user
        const data = localStorage.getItem(StorageName)
        if (data) {
            try {
                user = JSON.parse(data) as IStoredUser
            } catch (e){
                user = DefaultUser
            }
        } else {
            user = DefaultUser
        }
        return  user
    }

    getPuzzles = (): IPuzzle[] => {
        let puzzles = [] as IPuzzle[]
        const data = localStorage.getItem(PuzzleStorageName)
        if (data) {
            try {
                puzzles = JSON.parse(data) as IPuzzle[]
            } catch (e){
                return puzzles
            }
        }
        return  puzzles
    }

    setRequestDate() {
        const date = Date.now().toString()
        localStorage.setItem(RequestDateName, date)
    }

    getRequestDate() {
        const stPuzzles = this.getPuzzles()
        const startDate = !stPuzzles.length
            ? StartPuzzlesDate
            : Date.parse(stPuzzles[stPuzzles.length -1].createdAt)
        const requestDate = localStorage.getItem(RequestDateName) || startDate.toString()
        return JSON.parse(requestDate)   
    }

    saveUser(data = copyObj(DefaultUser)) {
        const savedData = this.getUser()
        const dataToSave = {...savedData, ...data}
        const storageData = JSON.stringify(dataToSave)
        localStorage.setItem(StorageName, storageData)
    }

    savePuzzles(data: IPuzzle[]) {
        localStorage.setItem(PuzzleStorageName, JSON.stringify(data))
    }

    delete(keys: string[] = []) {
        if (!keys.length) {
            localStorage.removeItem(StorageName)
        } else {
            for (const key of keys) {
                localStorage.removeItem(key)
            }
        }
    }
}

const storage = new Storage()

export default storage
