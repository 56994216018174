import {combineReducers} from 'redux'

import { AppType, IBoardPieces, IGame, IGameBoard, IGameSettings, IGameState, IPieces, IPuzzle, IRegedUser, IRootState, Move } from './../models/models';
import gameStateReducer, {InitialGameState} from './gameStateSlice'
import gameOptionsReducer, {InitialGameOptions} from './gameOptionsSlice'
import userReducer, {InitialUserState} from './userSlice'

import topReducer, {AppInitialState} from './topStateSlice'
import analyzeReducer, {InitialAnalysisState } from './analysisSlice';
import  towersReducer, {InitialBoardState} from "./boardSlice";
import gameReducer, {InitialGame} from './gameSlice';
import { createSelector } from '@reduxjs/toolkit';
import { TopState } from '../constants/gameConstants';
import { posAreEquil, splitMove } from '../local-engine/gameplay-helper-fn';

export const InitialState: IRootState = {
	// wsMessage: {message: null} as IWSMessage,
	user: InitialUserState,
	game: InitialGame,
	gameSettings: InitialGameOptions,
	topState: AppInitialState,
	analysis: InitialAnalysisState,
	board: InitialBoardState,
	gameState: InitialGameState,
};

export const rootReducer = combineReducers({
	gameState: gameStateReducer,
	game: gameReducer,
	gameSettings: gameOptionsReducer,
	user: userReducer,
	topState: topReducer,
	analysis: analyzeReducer,
	board: towersReducer,
}); 


export const selectUser = (state: IRootState) => state.user
export const selectAnalysis = (state: IRootState) => state.analysis
export const selectAppState = (state: IRootState) => state.topState
export const selectBoard = (state: IRootState) => state.board
export const selectGame = (state: IRootState) => state.game
export const selectGameState = (state: IRootState) => state.gameState
export const selectGameOptions = (state: IRootState) => state.gameSettings

export const selectUserWithProps = (props: string[]) => 
	createSelector([selectUser], (User) => {
		const result = {} as any
		for (const key of props) {
			result[key] = (User  as any)[key]
		}
		return result as IRegedUser
})

export const selectUserWithoutProps = (props: string[]) => createSelector([selectUser], (User) => {
	const result = {} as any
	for (const key in User) {
		if (!props.includes(key)) {
			result[key] = (User  as any)[key]
		}
	}
	return result as IRegedUser
})

export const selectWithProps = (props: string[], sub: string) => 
	createSelector([state => state], (State) => {
		const result = {} as any
		// console.log(State, props, sub)
		for (const key of props) {
			result[key] = (State[sub]  as any)[key]
		}
		return result
})

export const selectWithoutProps = (props: string[], sub: string) => 
	createSelector([state => state], (State) => {
		const result = {} as any
		for (const key in State[sub]) {
			if (!props.includes(key)) {
				result[key] = (State[sub]  as any)[key]
			}
		}
		return result
})

export const selectGameStateWithProps = (props: string[]) => 
	createSelector([selectGameState], (GameState) => {
		const result = {} as any
		for (const key of props) {
			result[key] = (GameState  as any)[key]
		}
		return result as IGameState
})

export const selectGameStateWithoutProps = (props: string[]) => 
	createSelector([selectGameState],(State) => {
		const result = {} as any
		for (const key in State) {
			if (!props.includes(key)) {
				result[key] = (State as any)[key]
			}
		}
		return result as IGameState
})

export const selectGameOptionsWithProps = (props: string[]) => 
	createSelector([selectGameOptions], (Options) => {
		const result = {} as any
		for (const key of props) {
			result[key] = (Options  as any)[key]
		}
		return result as IGameSettings
})

export const selectBoardWithProps = (props: string[]) => createSelector([selectBoard], (Board) => {
	const result = {} as any
	for (const key of props) {
		result[key] = (Board  as any)[key]
	}
	return result as IGameBoard
})

export const selectGameWithProps = (props: string[]) => createSelector([selectGame], (Game) => {
	const result = {} as any
	for (const key of props) {
		result[key] = (Game  as any)[key]
	}
	return result as IGame
})

export const selectGameWithoutProps = (props: string[]) => 
	createSelector([selectGame], (Game) => {
		const result = {} as any
		for (const key in Game) {
			if (!props.includes(key)) {
				result[key] = (Game  as any)[key]
			}
		}
		return result as IGame
})

export const selectPositionWithoutDom = createSelector(
	[state => state.game.position], 
	(pos: IBoardPieces) => {
		const position = {} as IPieces
		for (const key in pos) {
			const {DOM, ...piece} = pos[key]
			position[key] = {...piece}
		}
		return position
})

export const selectPiece = (key: string) => createSelector(
	[state => state.game.position],
	pos => pos[key]
)

export const selectLastMove = createSelector(
	[
		selectGameWithProps(['moves', 'lastMove', 'position']),
	], (gameProps) => {
		const {moves, lastMove, position} = gameProps
		if (!lastMove.turn || !moves[lastMove.num]) return ['']
		const prevMovesData = moves[lastMove.num][lastMove.turn] as Move
		if (!prevMovesData) return ['']
		const isActualPos = posAreEquil(position, prevMovesData.position)
		if (isActualPos) {
			return splitMove((prevMovesData).move)
		}
		return ['']
	})

export const selectPuzzle = createSelector(
	[
		selectWithProps(['puzzles','selectedPuzzle'], TopState), 
	], (data) => {
		const {puzzles = [], selectedPuzzle} = data
		return puzzles.find((p: IPuzzle) => p._id === selectedPuzzle)
	})

export const selectIsGame = (state: IRootState) => (state.gameSettings.rivalType === 'player' 
													&& state.gameState.gameConfirmed)
													
export const selectIsTouchable = createSelector(
	[selectAppState, selectGameState, selectUser, selectGame, selectAnalysis],
	(topState, gameState, user, game, analysis) => {
		const caseOne = topState.type === AppType.game 
			&& gameState.gameStarted
			&& game.turn === gameState.playerColor 
			&& game.nextMoves.map(m => m.move[0]) 
		const caseTwo = gameState.gameStarted 
			&& user.premoveAllowed 
			&& game.turn !== gameState.playerColor
			&& Object.keys(game.position).filter(k => game.position[k].color === gameState.playerColor)	
		const caseThree = (topState.type === AppType.puzzles || !user.exp.onboardingPassed)
			&& !topState.puzzleResolved
		const caseFour = topState.type === AppType.analysis 
			&& ((analysis.settingPosition && (!analysis.removingPieces
				|| (analysis.removingPieces 
				&& Object.keys(game.position).filter(k => k.length < 4))
			))
				|| (!analysis.settingPosition && game.nextMoves.map(m => m.move[0])))
	return caseOne || caseTwo || caseThree || caseFour
})
