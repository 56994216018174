
import { useSelector } from "react-redux"
import { I18n } from "../../assets/i18n"
import { selectLanguage } from "../../store/userSlice"

export const Ads = () => {
    const lang = useSelector(selectLanguage)
    const i18n = I18n[lang.slice(0, 2)] || I18n.en
    return <section className="ads-block popup">
                <h3>{i18n.adsInvuite || 'Your ad can be here!'}</h3>
                <p>email: tower-draughts@gmail.com</p>
            </section>
}