import createSagaMiddleware from 'redux-saga';
import { configureStore } from "@reduxjs/toolkit";

import { rootReducer} from './rootState&Reducer';
import sagaRoot from './sagas/sagaRoot';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({serializableCheck: false}).concat(sagaMiddleware),
})
sagaMiddleware.run(sagaRoot);
export default store;
export type AppStore = typeof store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch']
// export type Store = ReturnType<typeof storeFactory>
