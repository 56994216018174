import {useSelector} from 'react-redux'

import { GPClass } from '../../constants/gameConstants';
import { PieceColor, IBoardTower, IRootState } from '../../models/models';
// import crownF from '../../assets/images/crown.svg'
// import { updatePiece } from '../../store/game/gameSlice';

import './game-piece.scss';
import { FaCrown } from 'react-icons/fa6';
import { 
	selectBoardWithProps, selectPiece, selectUserWithProps
} from '../../store/rootState&Reducer';

interface FaceProps {
	white: number, black: number, colorW: boolean, king: boolean, view: string
}

export const Piece: React.FC<FaceProps> = (props) => {
	const { white, black, colorW, king, view } = props
	// console.log(white, black, props, !colorW ? (black || 1) : black, colorW ? (white || 1) : white)
	const WPClass = view === 'face' ? "white-piece" : 'white-checker'
	const BPClass = view === 'face' ? "black-piece" : 'black-checker'
	const BlackPieces = new Array(!colorW ? (black || 1) : black).fill(0) 
	const WhitePieces = new Array(colorW ? (white || 1) : white).fill(1)
	const towerPiecesArray = colorW
		? WhitePieces.concat(BlackPieces) 
		: BlackPieces.concat(WhitePieces)
	const middle = Math.floor(towerPiecesArray.length / 2)
	const Numbers = colorW 
		? <p className="numbers-on-hover">
			<span className="white-top">{white}</span>
			<span className="black-down">{black}</span>
		</p>  
		: <p className="numbers-on-hover">
			<span className="black-top">{black}</span>
			<span className="white-down">{white}</span>
		</p>
	const Tower = towerPiecesArray.map((pn: number, i: number) => {
		const pos = Math.abs(i - middle)
		const BlackOrWhite = pn ? WPClass : BPClass
		const UpOrDown = i <= middle ? `up${pos}` : `down${pos}`
		const KingAndTop = king && !i ? ' king' : ''
		const pieceClass = `${BlackOrWhite} ${UpOrDown}${KingAndTop}`
		return (
			<div key={i} className={pieceClass}>
				{king && !i ? <FaCrown /> : null}
				{!i ? <div className='top-piece-circle'><span className="top-piece-circle_inner" /></div> : null}
			</div>
		)
	})
	return (
		<div className="tower-wrapper">
			{Tower}
			{towerPiecesArray.length > 1 ? Numbers: null}
		</div>
	)
}

export interface ITowerProps {
	posKey: string,
	gamePiece: IBoardTower, 
	view: string, 
	pieceColors: string[],
	cellSize: number
}

export const Tower = (props: {posKey: string}) => {
    const {
		posKey,
	} = props
	const {cellSize} = useSelector(selectBoardWithProps(['cellSize', "towerView"]))
	const {
		pieceColors: pcs, towerView: view
	} = useSelector(selectUserWithProps(['pieceColors', 'towerView']))
	const piece = useSelector(selectPiece(posKey))
	const {
		DOM,
		color,
		king = false,
		mandatory: mt,
		white = +(piece.color === PieceColor.white),
		black = +(piece.color === PieceColor.black),
	} = piece
	let {x, y} =  DOM || {}
	const viewCl = view === 'face' ? ' towers' : ''
	const className = `${GPClass} ${color} pcs-${pcs[0]}-${pcs[1]} cs-${cellSize}${mt? ' m-tower': ''}${viewCl}`
	const style = {top: `${y}px`, left: `${x}px`} //  {transform: `translate(${x}px, ${y}px)`}//
	
	const colorW = color === PieceColor.white 

	const _props={
		white, 
		black, 
		colorW, 
		king, 
		view,
	}
	
	return 	<div className={className} data-indexes={posKey} style={style}>
				<Piece {..._props}/>
			</div>  
}

export const TowerComponent = (props: ITowerProps) => {
    const {
		posKey,
		gamePiece, 
		view,
		pieceColors: pcs,
		cellSize
	} = props
	// console.log(pcs)
	const {
		DOM,
		color,
		king = false,
		white = +(gamePiece.color === PieceColor.white),
		black = +(gamePiece.color === PieceColor.black),
		mandatory: mt,
	} = gamePiece || {}
	let {x, y} =  DOM || {}
	const className = `${GPClass} ${color} pcs-${pcs[0]}-${pcs[1]} cs-${cellSize}${mt? ' m-tower': ''}${view === 'face' ? ' towers' : ''}`
	const style = {top: `${y}px`, left: `${x}px`} //  {transform: `translate(${x}px, ${y}px)`}//
	const _props={
		white, 
		black, 
		colorW: gamePiece.color === PieceColor.white, 
		king, 
		view,
	}
	return 	<div className={className} data-indexes={posKey} style={style}>
				<Piece {..._props}/>
			</div>  
}

export default TowerComponent


export const BlackTower = () => {
	const cellSize = useSelector((state: IRootState) => state.board.cellSize)
	const towerView = useSelector((state: IRootState) => state.user.towerView)
	const gamePiece = {
		color: PieceColor.black,
		white: 0,
		black: 1,
		DOM: {x: 0, y: 0}
	}
	return <TowerComponent
				gamePiece={gamePiece} 
				view={towerView} 
				pieceColors={["black", "white"]} 
				cellSize={Math.max(cellSize -10, 25)} 
				posKey={''}
			/>
}

export const WhiteTower = () => {
	const cellSize = useSelector((state: IRootState) => state.board.cellSize)
	const towerView = useSelector((state: IRootState) => state.user.towerView)
	const gamePiece = {
		color: PieceColor.white,
		white: 1,
		black: 0,
		DOM: {x: 0, y: 0}
	}
	return <TowerComponent 
				gamePiece={gamePiece} 
				view={towerView} 
				pieceColors={["black", "white"]} 
				cellSize={Math.max(cellSize -10, 25)} 
				posKey={''}
			/>
}