import React from 'react'

import './logoIcon.scss'

export const Logo: React.FC<{size?: number}> = ({size = 20}) => {
    return  <div className={`logo s-${size}`}>
                <div className='white-piece'></div>
                <div className='black-piece'></div>
            </div>
}
