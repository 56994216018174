import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router} from 'react-router-dom'

// import Puzzles from './assets/basicPuzzles.json'
import RouterComponent from './AppRoutes';
import { Header } from './UI-components/Header&Nav/Header';
import Footer from './UI-components/Footer/Footer';

import './App.scss'
import { useDispatch, useSelector } from 'react-redux';
import Onboarding from './UI-components/Onboarding/Onboarding';
import { selectUserWithProps, selectWithProps } from './store/rootState&Reducer';

import { useMessageShow } from './hooks/useShowMessage';
import { TopState } from './constants/gameConstants';
import EventEndPopup from './UI-components/EventEndPopup/EventEndPopup';
// import { IPuzzle } from './models/models';
import { setAppMode } from './store/topStateSlice';
import Bot from './Bot';
import { isDev } from './local-engine/gameplay-helper-fn';
import { wsService } from './common/ws-service';
import OnlineBot from './ExternalBot';

const App: React.FC  = () => {
    const dispatch = useDispatch()
    const [modeChoosen, setModeChoosen] = useState(false)
    const [bot, setBot] = useState(false)
    const {showMessage} = useMessageShow()
    const {backMessage, appModeUser, puzzles, ads} = useSelector(
        selectWithProps(['backMessage', 'appModeUser', 'puzzles', "ads"], TopState)
    )
    const {puzzlesAdmin} = useSelector(selectUserWithProps(['puzzlesAdmin'])) as any
    const {
        theme, viewMode: mode, exp: {onboardingPassed}, name
    } = useSelector(selectUserWithProps([
        'theme', 'viewMode', 'exp', 'name'
    ]))

    useEffect(() => {
        isDev() && console.warn('app did mount')
        const unsubBot = wsService.$onlineBot.subscribe(setBot)
        return unsubBot
    }, [])
    useEffect(() => {
        const body = document.querySelector('body')
        if (body) {
            body.setAttribute('class', `theme-${theme} mode-${mode}`)
        }
    }, [theme, mode])

    useEffect(() => {
        showMessage(backMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backMessage])

    useEffect(() => {
        if (puzzles.length || !name) return
        // if (!token) {
        //     const puzzles = Puzzles as unknown as IPuzzle[]
        //     dispatch(setPuzzles(
        //         puzzles
        //         .slice()
        //         .filter(p => !resolvedPuzzles?.includes(p._id))
        //         .sort((a: IPuzzle, b: IPuzzle) => a.level - b.level)
        //     ))
        // } else {
        //     dispatch(getPuzzles())
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name])

    const handleChooseMode = (mode: boolean) => {
        setModeChoosen(true)
        !mode && dispatch(setAppMode(mode))
    }
// isDev() && console.log('extra bot', bot)
    if (puzzlesAdmin && !modeChoosen) {
        return (
            <div className='choose-mode'>
                <button type='button' onClick={() => handleChooseMode(true)}>
                    user
                </button>
                <button type='button' onClick={() => handleChooseMode(false)}>
                    bot
                </button>
            </div>
        )
    }
    if (!appModeUser) {
        return <Bot />
    }
    return (
        <Router>
            <Header />
            { !onboardingPassed && name
                ? <Onboarding />
                : <RouterComponent />
            }
                {ads ? <EventEndPopup /> : null}
                <Footer />
            { bot ? <OnlineBot /> : null }
        </Router>
          
    );
}

export default App;
