import { createSlice } from "@reduxjs/toolkit";
import { CellsMap, IGameBoard, IRootState, TowerTouched } from "../models/models";
import { BaseCellSize, BaseBoardSize } from "../constants/gameConstants";
import { createCellsMap } from "../local-engine/board-helper-fn";


export const InitialBoardState: IGameBoard = {
    towerTouched: null as unknown as TowerTouched,
    cellSize: BaseCellSize,
    cellsMap: createCellsMap(BaseBoardSize) as CellsMap,
    boardSize: BaseBoardSize,
    reversedBoard: false,
    premove: null as unknown as {from: string, to: string}
}

const boardSlice = createSlice({
    name: 'board',
    initialState: InitialBoardState,
    reducers: {
        turnBoard(state, action) {state.reversedBoard = action.payload},
        setTouchedTower(state, action) {state.towerTouched = action.payload},
        updateBoardState(state, action) {return {...state, ...action.payload}},
        setBoardSize(state, action) {state.boardSize = action.payload},
        setPremove(state, action) {state.premove = action.payload}
    }
})

export const {
    setBoardSize,
    setTouchedTower,
    updateBoardState,
    turnBoard,
    setPremove
} = boardSlice.actions

export default boardSlice.reducer

export const selectCellsSize = (state: IRootState) => state.board.cellSize
