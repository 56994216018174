import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { UnknownAction } from 'redux-saga';
import { Axios, setAuthorizationHeader } from '../../common/axios';

import { 
    saveUser, logout, checkStorage, followPlayer, blockPlayer, setToken, close
} from '../userSlice'
import { 
    AppInitialState, getNewPuzzles, sendWsMessage, setAppState, setBackMessage, 
    setPuzzles,
} from '../topStateSlice';
import storage, {DefaultUser} from '../../common/storage';
import { copyObj, filterAndSort, isDev } from '../../local-engine/gameplay-helper-fn';
import { IRootState } from '../../models/models';
import { updateStoredUser } from '../../common/helpers/dataConvertors';
import { InitialGame, updateGame } from '../gameSlice';
import { InitialGameState, setGameState } from '../gameStateSlice';
import { InitialAnalysisState, updateAnalysisState } from '../analysisSlice';
import { InitialBoardState, updateBoardState } from '../boardSlice';
import { InitialGameOptions, updateOptions } from '../gameOptionsSlice';
import { PuzzleStorageName, RequestDateName, StorageName, TwelveH } from '../../constants/gameConstants';
import { wsService } from '../../common/ws-service';
import BacisPuzzles from '../../assets/basicPuzzles.json'

function* workerStorage() {
    // yield put(getPuzzles())
    const userData = storage.getUser()
    isDev() && console.log('stared user', copyObj(userData))
    const version = require('../../../package.json').version
    if (userData.name && version !== userData._v) {
        updateStoredUser(userData, DefaultUser)
    }
    if (!userData.token) {
        let user = userData || copyObj(DefaultUser)
        if (!userData.exp.onboardingPassed) {
            user.language = navigator.language.slice(0, 2)
        }
        yield put(saveUser(user))
        storage.saveUser(user)
        yield put(setPuzzles(BacisPuzzles))
        return
    }
    try {
        const {games: gs, ratings: rs, resolvedPuzzles = [], ...data} = userData
        const payload = JSON.stringify(data)
        const respond: {[key: string]: any} = yield call(Axios.post, '/api/auth/token', payload)
        const {ratings, games, token} = respond.data
        isDev() && console.log('new token', token)
        yield put(saveUser({...userData, ratings, games, token}))
        storage.saveUser({ratings, games, token})
        yield put(sendWsMessage({message: 'user authorized', payload: {token}}))
        if (Date.now() - storage.getRequestDate() >= TwelveH) {
            yield put(getNewPuzzles())
        } else {
            yield put(setPuzzles(filterAndSort(storage.getPuzzles(), resolvedPuzzles)))
        }
    } catch(e: any) {
        console.error(e)
        if (e.response && e.status < 500) {
            yield put(saveUser(DefaultUser))
            storage.delete()
        } else {
            yield put(saveUser(userData))
        }
        const mess = {msg: e.response?.data?.message || e.message, ok: false}
        yield put(setBackMessage(mess))
    } finally {
        
    }
}

function* workerClose() {
    const {user: {ratings, games, token, ...restData}} = yield select()
    try {
        setAuthorizationHeader(token)
        const payload = JSON.stringify(restData)
        yield call(Axios.post, 'api/user/save', payload)
    } catch (e: any) {
        console.error(e)
    }
}

function* workerLogout() {
    const {ratings, games, token, ...restData} = storage.getUser() 
    const {
        board: {cellSize, cellsMap}} = yield select()
    isDev() && console.log('logout', restData)
    try {
        setAuthorizationHeader(token)
        const payload = JSON.stringify(restData)
        yield call(Axios.post, 'api/user/save', payload)
    } catch (e: any) {
        console.error(e)
        const mess = {msg: e.response?.data?.message || e.message, ok: false}
        yield put(setBackMessage(mess))
    }
    wsService.setToken(false)
    yield put(saveUser({...DefaultUser, puzzlesAdmin: false}))
    yield put(updateGame(InitialGame))
    yield put(setGameState(InitialGameState))
    yield put(updateAnalysisState(InitialAnalysisState))
    yield put(updateBoardState({...InitialBoardState, cellSize, cellsMap}))
    yield put(updateOptions(InitialGameOptions))
    yield put(setAppState(AppInitialState))
    storage.delete([StorageName, PuzzleStorageName, RequestDateName])
    yield put(sendWsMessage({message: 'logout'}))
    setAuthorizationHeader('')
}

function* workerRelations(action: UnknownAction) {
    yield delay(0)
    const {user} = (yield select()) as IRootState
    try {
        setAuthorizationHeader(user.token)
        const payload = JSON.stringify(user)
        const respond: {[key: string]: any} = yield call(Axios.post, 'api/user/save', payload)
        const {token} = respond.data || {}
        if (token) {
            yield put(setToken(token))
        }
    } catch(e: any) {
        console.error(e)
        const mess = {msg: e.response?.data?.message || e.message, ok: false}
        yield put(setBackMessage(mess))
    } finally {
        const {user} = (yield select()) as IRootState
        storage.saveUser(user)
    }
}

export default function* watcherUser() {
    yield takeLatest(checkStorage, workerStorage)
    yield takeLatest(followPlayer, workerRelations)
    yield takeLatest(logout, workerLogout)
    yield takeLatest(blockPlayer, workerRelations)
    yield takeLatest(close, workerClose)
}
