import { delay, put, select, takeLatest } from 'redux-saga/effects';
import { setGameVariant } from '../gameOptionsSlice'

import { moveR } from '../../local-engine/move-r';
import { AppType, BoardNotation, IMoves, IPuzzle, IRootState } from '../../models/models';
import { setBoardSize, turnBoard, updateBoardState } from '../boardSlice';
import { UnknownAction } from 'redux';
import { copyObj, isDev } from '../../local-engine/gameplay-helper-fn';
import { createCellsMap, createOutBoardTowers, updatePiecesPosition } from '../../local-engine/board-helper-fn';
import { updateGame, updatePosition } from '../gameSlice';
import { setBoardNotation, setView } from '../userSlice';
import { BaseBoardSize, InternationalBoardSize } from '../../constants/gameConstants';
import storage from '../../common/storage';
import basicPuzzles from '../../assets/basicPuzzles.json'
import { setPuzzles } from '../topStateSlice';


function* workerGameVariant(action: UnknownAction) {
    const {
        topState: {type},
        user: {resolvedPuzzles, boardNotation},
    } = (yield select()) as IRootState
    let {GV, boardSize} = action.payload as any
    if (GV === 'international' && boardNotation !== BoardNotation.dr) {
        yield put(setBoardNotation(BoardNotation.dr))
    }
    boardSize = boardSize || (GV === 'international'
        ? InternationalBoardSize 
        : BaseBoardSize)
    if (type === AppType.puzzles) {
        const _puzzles = storage.getPuzzles().length 
            ? storage.getPuzzles() 
            : basicPuzzles as unknown as IPuzzle[]
        const puzzles = _puzzles.filter(p => p.gType === GV.slice(0,3)
            && !resolvedPuzzles?.includes(p._id)
        ).slice(0,6)
        yield put(setPuzzles(puzzles))
        yield delay(100)
    }
    const {
        board: {boardSize: bs, cellSize: cS, reversedBoard},
        user: {towerView},
    } = yield select()
    
    moveR.setProps({GV, size: boardSize})
    if (GV === 'towers' && towerView !== 'face') { 
        yield put(setView('face'))
    }
    isDev() && console.warn(moveR)
    const cellSize = boardSize !== bs ? Math.round(cS * bs/boardSize/10)*10  : cS
    const cellsMap = createCellsMap(boardSize, cellSize, reversedBoard)
    yield put(updateBoardState({cellSize, cellsMap, boardSize}))
    yield delay(310)
    const {game: {position: pos}} = yield select()
    const towers = type === AppType.analysis 
        ? createOutBoardTowers({}, boardSize, GV)
        : pos
    const position = updatePiecesPosition(towers, cellsMap, cellSize, boardSize)
    yield put(updatePosition(position))
}

function* workerBoardSize(action: UnknownAction) {
    const {
        board: {boardSize: bs, cellSize: cS, reversedBoard},
        gameSettings: {gameVariant: GV},
    } = (yield select()) as IRootState
    const boardSize = action.payload as number
    moveR.setProps({GV, size: boardSize})
    const cellSize = boardSize !== bs ? Math.round(cS * bs/boardSize/10)*10  : cS
    const cellsMap = createCellsMap(boardSize, cellSize, reversedBoard)
    yield put(updateBoardState({cellSize, cellsMap, boardSize}))
    
}

function* workerTurnBoard(action: UnknownAction) {
    const revBoard = action.payload as boolean
    const {
        board: {boardSize, cellSize}, 
        game: {position: pos, moves: ms}} = (yield select()) as IRootState
    const cellsMap = createCellsMap(boardSize, cellSize, revBoard)
    yield delay(50)
    const position = updatePiecesPosition(pos, cellsMap, cellSize)
    const moves = (copyObj(ms) as IMoves).map(move => {
        if (move.black) {
            move.black.position = updatePiecesPosition(move.black.position, cellsMap, cellSize)
        }
        move.white!.position = updatePiecesPosition(move.white!.position, cellsMap, cellSize)
        return move
    })
    yield put(updateGame({position, moves}))
    yield put(updateBoardState({cellsMap}))
}

export default function* watcherPreGame() {
    yield takeLatest(turnBoard, workerTurnBoard)
    yield takeLatest(setBoardSize, workerBoardSize)
    yield takeLatest(setGameVariant, workerGameVariant)
}
