export const validateEmail = (email: string) => {
    const isError = /^\w+.+\w+@\w+\.\w{1,3}$/.test(email);
    if (!isError) {
        return {message: 'invalid e-mail, please fix it'}
    }
    return  {message: ''}
}

const validate = (password: string) => {
    if ( /[\s]/.test(password) || password.length < 6) return false;
    return /[!&^%$#*]/g.test(password)
}

export const validatePassword = (password: string) => {
    const isStrong = validate(password)
    if (!isStrong) {
        return {message: 'password must contain one of !&^%$#* charters end have at least length 6'}
    }
    return {message: ''}
}

export const validateName = (name: string) => {
    const isError = /\S{6,15}/.test(name);
    if (!isError) {
        return {message: 'name must have at least 6 non space characters'}
    }
    return  {message: ''}
}

export type valArgs = 'name' | 'password' | 'email'

const validators = (type: valArgs) => 
    type === 'name' ? validateName : (type === 'email' ? validateEmail : validatePassword) 

export default validators
