import { ReactNode } from "react"

export interface IChildren {children: ReactNode | undefined}

// root
export interface IRootState {
    gameState: IGameState
    game: IGame
    gameSettings: IGameSettings
    topState: ITopState
    user: IRegedUser
    analysis: IAnalysisState
    board: IGameBoard
} 
export interface IGame {
    lastMoveSquares?: string[]
    turn: PieceColor
    moves: IMoves
    nextMoves: MMRResult[]
    position: IBoardPieces
    lastMove: IGoTo 
    moveStep: number
    idleInRow: number
    hintMove: string
    startTurn: PieceColor
    startPosition: IBoardPieces
    helpRequest: boolean
    playingMoves: boolean
}
export interface IGameState {
    gameKey: string
    gameStarted: boolean
    gameConfirmed: boolean
    playerColor: PieceColor
    whiteClock: number
    blackClock: number
    white: Partial<IPlayer>
    black: Partial<IPlayer>
    rivalOfferedDraw: boolean
    drawOffered: boolean
    result: GameResult
    playerOnline: number
    rivalOffline: number
    waitingForRival: boolean
}
export interface IGameSettings {
    timing: Timing
    competitionType: CompetitionType
    selectedColor: PieceColor | 'random'
    rivalType: RivalType
    rivalLevel: number
    gameVariant: GameVariant
}

export interface IRegedUser {
    token: string
    createdAt?: Date
    userId: string
    name: string
    theme: string
    language: string
    volume: number
    games?: any[]
    viewMode: string
    pieceColors: string[]
    ratings: IRatings
    exp: IExpirience
    blocked: string[]
    friends: string[]
    lastSave?: number
    resolvedPuzzles?: string[]
    highlightArrow: boolean
    highlightLast: boolean
    highlightNext: boolean
    boardNotation: BoardNotation
    towerView: string
    premoveAllowed?: boolean
    highlightPieces: boolean
    _v?: string
}

export interface ITopState {
    windowSize: { width: number; height: number }
    type: AppType
    wsMessage: IWSMessage | null
    backMessage: IBackMessage
    localEngine: boolean
    wsConnectionState: number
    puzzles: IPuzzle[]
    selectedPuzzle: string
    solution?: Move[]
    eSolution?: Move[]
    puzzleResolved: {resolved: boolean, animated: boolean} | null
    userMessage?: { message: string; context: string } | null
    commonChat?: IChatMessage[]
    gameChat?: IChatMessage[]
    appModeUser: boolean
    isDevice: boolean
    ads: boolean
    onboardingStep: number
    getPuzzles?: {[key: string]: number | Date}
}

export interface IGameBoard extends IBoardBase {
    // lastMoveSquares?: string[]
    // towerView: string
    towerTouched?: TowerTouched
    boardSize: number
    topLegend?: string[]
    sideLegend?: number[]
    // boardNotation: BoardNotation
    reversedBoard: boolean
    premove?: {from: string, to: string}
}

export interface IAnalysisState {
    evalDepth: number
    bestMoveLines: { line: string[]; value: number }[]
    gameMoves: IMoves
    settingPosition: boolean
    removingPieces: boolean
    settingKing: boolean
    unused: IBoardPieces,
    // startTurn: PieceColor
}

export interface IChatMessage {
    [key: string]: any
}

export enum AppType {
    game = 'game',
    analysis = 'analysis',
    puzzles = 'puzzles'
}

export enum GameVar {
    tow = "Towers",
    int = "International",
    rus = "Russian",
}
export interface IRatings {
    rus: IRating[]
    int: IRating[]
    tow: IRating[]
    [key: string]: IRating[]
}

export interface IRating {
    rating: number
    date: Date
}

export interface IPuzzle {
    position: IPieces | string
    solution: string[] | string
    description: string,
    [key: string]: any
}
export interface IWSMessage {
    message: any
}


export type CompetitionType = 'ranked' | 'casual' | 'tournament'

export type GameVariant = 'towers' | 'russian' | 'international'

export type IStoredUser = IRegedUser

export interface IUserMessage {
    content: string
    from: string
    to: string
    date: Date
    emoji: number
}

export interface ITimer {
    min: string
    sec: string
}

export interface IClock extends Timing {
    timeForFirstMove?: number
}

export interface Timing {
    gameTimeLimit: number
    adds: number
}

export interface IError {
    message: string | null
}

// board
export interface IBackMessage {
    msg: string, ok: boolean
}

export enum BoardNotation {
    ch = 'chess',
    dr = 'draughts',
    no = 'none'
}

// export interface IPositionsTree {[key: string]: IBoardToGame}
export interface PositionsTree {
    [key: string]: IBoardPieces
}

export interface CellsMap {
    [key: string]: IPiecePosition
}

export interface IBoardProps extends IGameBoard {
    possibleMoves?: CellsMap
    lastMove: string[]
}

export interface IBoardBase {
    cellsMap: CellsMap
    cellSize: number
}

export interface ICell {
    type?: 'light' | 'dark'
    indexes?: string

    [propName: string]: any
}

// towers & pieces

// export enum PieceType {
//     man = 'man',
//     king = 'king',
// }

export enum PieceColor {
    black = 'black',
    white = 'white',
}
export interface IPiecePosition {
    x: number
    y: number
}

export interface IShortPiece {
    w?: number,
    b?: number,
    c: PieceColor,
    k?: number
}

export interface IShortPieces {
    [key: string]: IShortPiece
}

export interface IBoardPieces {
    [key: string]: IBoardTower
}

export interface IRef<T> {
    readonly current: T | null
}

export enum Directions {
    lu = 'leftUp',
    ld = 'leftDown',
    ru = 'rightUp',
    rd = 'rightDown',
}
export interface IBoardTower extends ITower {
    DOM?: IPiecePosition
    mandatory?: boolean
}

export interface PuzzlesLevel {
    tow: number, 
    rus: number, 
    int: number
}

export interface IExpirience {
    onboardingPassed: boolean
    // declaredRatings: {
    either: IGVExpo
    rus: IGVExpo
    int: IGVExpo
    tow: IGVExpo
    // }
    // rules: {
    //     either: number
    //     rus: number
    //     int: number
    //     tow: number
    // }
    // offlineGames: {
    //     tow: IGVData
    //     rus: IGVData
    //     int: IGVData
    // }
    [key: string]: any 
}

export interface IGVExpo {
    dRate: number, rules: number, offGames: IGVData, puzzles: number
}

export interface IGVData {
    num: number, highest: number, winrate: number
}
export interface IChecker {
    // type: PieceType
    king?: boolean
    color: PieceColor
}

export interface ITower extends IChecker {
    white: number
    black: number
}

export interface IPieces {
    [k: string]: ITower
}

export interface IBoardPiece extends IChecker {
    DOM?: IPiecePosition
    mandatory?: boolean
}

export interface INewGameProps {
    gameKey: string
    white: IPlayer
    black: IPlayer
    whiteClock: IClock
    blackClock: IClock
}

export interface Move {
    move: string
    position: IBoardPieces
    takenPieces?: string[]
}

export interface IAnimBoard {
    stop: boolean, 
    moves: Move[], 
    cS: number, 
    stPos: IBoardPieces, 
    cM: CellsMap, 
    bS: number,
    pCs: string[]
}

export interface IBaseDemo {
    moves: Move[]
    position: IBoardPieces
    cM: CellsMap,
    bS: number
    cS: number
}

export interface IDemoState {
    moveNum: number
    demoStarted: boolean
    move?: string[]
    mStep?: number
}

export interface IMove {
    gameKey: string
    move: string
    position?: IShortPieces
    receivedAt?: Date
    whiteClock?: IClock
    blackClock?: IClock
}

export interface IKingDiagonals {
    [key: string]: IKingDiagonal
}

export interface IKingDiagonal {
    dir: string,
    line: IKingDiagonalPoint[]
    taken: string[]
}
export interface IKingDiagonalPoint {
    point: string,
    piece: ITower
}

export type IMoves = Partial<IMovesPair>[]

export interface IMovesPair {
    white: Move,
    black: Move
}

export interface IGoTo { num: number; turn: PieceColor }

export interface TowerTouched {
    key: string
    possibleMoves: CellsMap
    startCursorPosition: IPiecePosition
    startTowerPosition: IPiecePosition
    color: PieceColor
    king: boolean
    mouseDown: boolean
}

export interface EngineBoard {
    [key: string]: BoardCell
}

export enum Online {
    online,
    offline,
    reconnecting,
}
export interface IPlayer {
    name: string
    onlineStatus?: Online
    userId: string
    rating: number
    ratingChanges?: number[]
    offerredDraw?: boolean
}

export type EndGameReason =
    | 'surrender'
    | 'timeIsUp'
    | 'noMoves'
    | 'draw'
    | 'abandonedByWhite'
    | 'abandonedByBlack'

export enum GameResult {
    white = '1-0',
    black = '0-1',
    draw = '1/2-1/2',
    canceled = 'canceled'
}

export interface IGameData {
    gameKey: string
    gameType: string
    result: GameResult
    reason: EndGameReason
    white: IPlayer
    black: IPlayer
    moves: IMoves 
    gameVariant: GameVariant
    timing: string
    date: Date
}

export interface BoardCell {
    pos?: IPiecePosition
    neighbors: INeighborCells
    boardKey: string
}

export interface INeighborCells {
    [key: string]: string
}

export interface Diagonals {
    [key: string]: BoardCell[]
}

export interface MMRResult {
    move: string[]
    endPos: IBoardPieces
    takenPieces?: string[]
}

export interface MResult {
    move: string[]
    startPos: IBoardPieces
}

export interface FreeMRResult extends MResult {
    endPos?: IBoardPieces
}

export interface MMStepProps extends MResult {
    takenPieces: string[]
}

export type RivalType = 'player' | 'engine'

// evaluation
export interface DeepValue {
    depth: number
    value: number
    move: string
}

export interface IPosition {
    position: IPieces
    turn: PieceColor
}

export interface IEvaluationData {
    moves: Move[]
    extraData?: { [key: string]: any }
    deepValue: DeepValue
}

export interface ITowersData {
    value: number
    pieces: number
    kings: number
    towersT: number
    towersB: number
    moveNumber: number
}

export interface IPositionData {
    white: ITowersData
    black: ITowersData
}

export interface Children {
    [key: string]: Branch
}

export interface Branch extends Partial<IPosition>{
    children: Children
    move: string
    deepValue: DeepValue
    turn: PieceColor
}
